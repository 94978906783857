<template>
    <div
        class="relative"
        :style="{ background: getByKey('main_nav_background') }"
        @mouseleave="closeMegaMenu"
    >

        <div class="container mx-auto">

            <ul class="flex justify-between items-center font-semibold py-3 text-grey-darker space-x-3 flex-wrap text-md h-12">
                <li
                    v-for="(item, index) in desktopNavItems"
                    ref="navItems"
                    :key="index"
                    class="whitespace-nowrap"
                >
                    <span @mouseover="openMegaMenu(index)">
                        <HeaderNavLink :url="item.menu_items_id.url">
                            {{ item.menu_items_id.label }}
                        </HeaderNavLink>
                    </span>
                </li>
            </ul>

        </div>

        <HeaderNavMegaMenu
            v-if="currentNavItem"
            :item="currentNavItem"
            :caret-pos="caretPos"
            @close="closeMegaMenu"
        />

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderNavMegaMenu from '@/components/HeaderNavMegaMenu';
import HeaderNavLink from '@/components/HeaderNavLink';

export default {

    components: {
        HeaderNavLink,
        HeaderNavMegaMenu,
    },

    data() {
        return {
            currentNavItem: {},
            caretPos: null,
        };
    },

    computed: {
        menu() {
            return this.$store.getters['content/menu'];
        },
        ...mapGetters('settings', [
            'getByKey',
        ]),
        ...mapGetters('content', [
            'megaMenuOpen',
        ]),
        desktopNavItems() {
            return this.$store.getters['content/desktopNavItems'];
        },
    },

    methods: {
        openMegaMenu(navItemIndex) {
            // if the nav item has child categories, switch to it and open the menu
            // otherwise, close the menu
            this.currentNavItem = this.menu.items[navItemIndex]?.menu_items_id;
            if (this.currentNavItem.grids.length > 0) {
                const navItemElRect = this.$refs.navItems[navItemIndex].getBoundingClientRect();
                this.caretPos = navItemElRect.left + (navItemElRect.width / 2);

                this.$store.dispatch('content/setMegaMenuOpen', true);
            } else {
                this.closeMegaMenu();
            }
        },
        closeMegaMenu() {
            this.$store.dispatch('content/setMegaMenuOpen', false);
        },
    },

};
</script>

<style lang="scss" scoped>
a {
    @apply no-underline;
    &:hover {
        @apply text-primary;
    }
}
</style>
