<template>
    <transition name="fade">
        <div v-show="isOpen">
            <div class="dropdown p-5 leading-none">

                <div class="text-xs tracking-wide font-bold text-grey-dark select-none pb-5">
                    MY ACCOUNT
                </div>

                <ul class="text-sm font-semibold">
                    <li
                        class="mb-4"
                        @click="close"
                    >
                        <nuxt-link
                            class="no-underline hover:text-primary transition-fast flex items-center"
                            to="/account/details"
                        >
                            <LicenseIcon class="h-4 mr-3" />
                            <span>My Details</span>
                        </nuxt-link>
                    </li>
                    <li
                        class="mb-4"
                        @click="close"
                    >
                        <nuxt-link
                            class="no-underline hover:text-primary transition-fast flex items-center"
                            to="/account/orders"
                        >
                            <DeliveryIcon class="h-4 mr-3" />
                            <span>My Orders</span>
                        </nuxt-link>
                    </li>
                    <li>
                        <span
                            class="no-underline hover:text-primary transition-fast flex items-center cursor-pointer"
                            @click="logOut"
                        >
                            <ExitIcon class="h-4 mr-3" />
                            <span>Log Out</span>
                        </span>
                    </li>
                </ul>

            </div>
            <div class="caret" />
        </div>
    </transition>
</template>

<script>
import LicenseIcon from '~/static/fonts/LineIcons/licencse.svg';
import DeliveryIcon from '~/static/fonts/LineIcons/delivery.svg';
import ExitIcon from '~/static/fonts/LineIcons/exit.svg';

export default {

    components: {
        LicenseIcon,
        DeliveryIcon,
        ExitIcon,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },
        logOut() {
            this.$store.dispatch('user/logout');
            this.close();
        },
    },

};
</script>

<style lang="scss" scoped>
.dropdown {
    border: 2px solid;
    top: 100%;
    right: 50%;
    transform: translateX(50%);
    @apply border-primary bg-white absolute whitespace-nowrap mt-3 rounded text-left shadow-md;
}
.caret {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 6px solid black;
    bottom: -12px;
    right: 50%;
    transform: translateX(50%);
    @apply border-primary;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.fade-enter-active,
.fade-leave-active {
    @apply transition-slow;
}
.fade-enter,
.fade-leave-to {
    @apply opacity-0;
    transform: translateY(10px);
}
</style>

