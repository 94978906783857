<template>
    <HeaderNavLink
        :url="component.url"
        class="grid-component-button text-white font-semibold flex justify-center items-center bg-primary focus:ring"
        :class="`style-${component.style}`"
    >
        <slot name="icon" />
        <span>
            <slot>{{ component.label }}</slot>
        </span>
    </HeaderNavLink>
</template>

<script>
import Spinner from '~/components/Spinner';
import HeaderNavLink from '@/components/HeaderNavLink';

export default {

    components: {
        HeaderNavLink,
        Spinner,
    },

    props: {
        component: {
            type: Object,
            default: () => {},
        },
    },

};
</script>

<style lang="scss" scoped>
.grid-component-button {
    @apply px-4 py-3 rounded-full transition-fast select-none flex text-center leading-tight;
    &:hover {
        &:not(:disabled) {
            @apply opacity-75 shadow-md;
        }
    }
    &:disabled {
        @apply opacity-50 cursor-default;
    }
    @media (max-width: config("theme.screens.sm")) {
        @apply px-1;
        font-size: 90%;
        min-height: 2.5rem;
        height: auto;
    }
}
</style>
