<template>
    <div
        v-on-clickaway="closeDropdown"
        class="relative z-40"
    >

        <div
            :class="{ 'text-primary': isDropdownOpen }"
            class="flex items-center hover:text-primary cursor-pointer font-semibold transition-fast"
            @click="openDropdown"
        >
            <UserIcon class="user-icon" />
            <span class="text-xs hidden">MY ACCOUNT</span>
        </div>

        <HeaderMainMyAccountDropdown
            :is-open="isDropdownOpen"
            @close="closeDropdown"
        />

    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import UserIcon from '~/static/fonts/LineIcons/user.svg';
import HeaderMainMyAccountDropdown from '~/components/HeaderMainMyAccountDropdown';

export default {

    components: {
        UserIcon,
        HeaderMainMyAccountDropdown,
    },

    mixins: [
        clickaway,
    ],

    data() {
        return {
            isDropdownOpen: false,
        };
    },

    watch: {
        $route() {
            this.closeDropdown();
        },
    },

    methods: {
        openDropdown() {
            this.isDropdownOpen = true;
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        },
    },

};
</script>

<style scoped>
.user-icon {
    height: 22px;
}
</style>
