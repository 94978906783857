import state from './state';
import actions from '@/store/product/actions';
import mutations from '@/store/product/mutations';
import getters from '@/store/product/getters';

// store definition
export default {
    state,
    actions,
    mutations,
    getters,
};
