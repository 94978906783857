/**
 * Runs on initial page load, fetching content for the site.
 */
import handleException from './error-handler.client';

export default async ({ app: { $axios, store } }) => {
    try {
        await store.dispatch('content/getMenu');
    } catch (e) {
        handleException(e);
    }
};
