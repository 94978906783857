<template>
    <div>

        <h5 class="font-heading text-2xl mb-2 font-light">
            About Us
        </h5>
        <p class="text-xs">
            Bubblegum Balloons is run by three friends with a shared
            passion for creativity and beautiful things. We often
            get asked how we got into the balloon business,
            so we thought we would put the story out
            there for everyone to read. <nuxt-link to="/about-us">
                More...
            </nuxt-link>
        </p>

    </div>
</template>

<script>
export default {

};
</script>

<style>
</style>
