import initialState from './initialState';

export default {

    reset(state) {
        const { productsChecked } = state;
        Object.assign(state, initialState);
        state.productsChecked = productsChecked;
    },

    setSteps(state, steps) {
        state.steps = steps;
    },

    setFirstName(state, firstName) {
        state.steps.firstName.value = firstName;
    },

    setLastName(state, lastName) {
        state.steps.lastName.value = lastName;
    },

    setEmail(state, email) {
        state.steps.email.value = email;
    },

    setPhone(state, phone) {
        state.steps.phone.value = phone;
    },

    setGiftMessage(state, giftMessage) {
        state.steps.giftMessage.value = giftMessage;
    },

    setShippingAddress(state, shippingAddress) {
        state.steps.shippingAddress.value = shippingAddress;
    },

    setShippingAddressFirstName(state, firstName) {
        state.steps.shippingAddress.value.firstName = firstName;
    },

    setShippingAddressLastName(state, lastName) {
        state.steps.shippingAddress.value.lastName = lastName;
    },

    setBillingAddress(state, billingAddress) {
        state.steps.billingAddress.value = billingAddress;
    },

    setBillingAddressFirstName(state, firstName) {
        state.steps.billingAddress.value.firstName = firstName;
    },

    setBillingAddressLastName(state, lastName) {
        state.steps.billingAddress.value.lastName = lastName;
    },

    setShowShippingAddressLookup(state, showAddressLookup) {
        state.steps.shippingAddress.showAddressLookup = showAddressLookup;
    },

    setShowBillingAddressLookup(state, showAddressLookup) {
        state.steps.billingAddress.showAddressLookup = showAddressLookup;
    },

    setSelectedDeliveryDay(state, selectedDeliveryDay) {
        state.steps.delivery = {
            ...state.steps.delivery,
            selectedDeliveryDay,
        };
    },

    setSelectedDeliveryOption(state, selectedDeliveryOption) {
        state.steps.delivery.value = selectedDeliveryOption;
    },

    setAvailableDeliveryOptions(state, availableDeliveryOptions) {
        state.steps.delivery = {
            ...state.steps.delivery,
            availableDeliveryOptions,
        };
    },

    setDeliveryOptionsError(state, deliveryOptionsError) {
        state.steps.delivery = {
            ...state.steps.delivery,
            deliveryOptionsError,
        };
    },

    setProductDeliveryAvailability(state, { productId, available, price }) {
        state.productsChecked.push({
            id: productId,
            available,
            price,
        });
    },

    setPrefersBoxes(state, prefersBoxes) {
        state.steps.delivery.prefersBoxes = prefersBoxes;
    },

    resetProductsChecked(state) {
        state.productsChecked = [];
    },

    setDeliveryType(state, value) {
        state.steps.deliveryType.value = value;
    },

    SET_PAYMENT_PROCESSING(state, isProcessing) {
        state.steps.payment.isProcessing = isProcessing;
    },

    SET_TRANSACTION(state, transaction) {
        state.transaction = transaction;
    },

    SET_GATEWAY(state, gateway) {
        state.gateway = gateway;
    },

    SET_INITIATIVE_CONTEXT(state, initiativeContext) {
        state.initiativeContext = initiativeContext;
    },
};
