<template>
    <div>

        <div
            :class="{ 'menu--open' : isOpen }"
            class="menu w-full sm:w-1/2 py-4 overflow-y-auto flex flex-col justify-between"
            :style="{ background }"
        >
            <div class="flex items-center justify-between px-8">
                <HeaderMainMobileAccount />
                <div
                    class="flex items-center font-semibold text-black link cursor-pointer"
                    @click="close"
                >
                    <PlusIcon
                        class="w-6 h-6 mr-2 transition-fast"
                    />
                    Close
                </div>
            </div>

            <HeaderDelivery class="px-8 py-4 mt-4 bg-pink-100" />

            <HeaderMainMobileSearch class="bg-grey-dark text-white mx-8 my-4 px-4 py-4 rounded bg-opacity-25 justify-start flex gap-2">
                Search
            </HeaderMainMobileSearch>

            <div>
                <component
                    :is="getComponentForItem(item)"
                    v-for="(item, index) in navItems"
                    :key="index"
                    :item="item.menu_items_id"
                    :is-open="openCollapseIndex === index"
                    @toggle-collapse="toggleCollapse(index)"
                />
            </div>
        </div>

        <div>
            <slot />
            <div
                :class="{ 'overlay--shown' : isOpen }"
                class="overlay"
                @click="close"
            />
        </div>

    </div>
</template>

<script>
import SlideMenuCollapse from '~/components/SlideMenuCollapse';
import SlideMenuItem from '~/components/SlideMenuItem';
import PlusIcon from '~/static/fonts/LineIcons/close.svg';
import HeaderMainMobileAccount from './HeaderMainMobileAccount';
import HeaderMainMobileSearch from './HeaderMainMobileSearch';
import HeaderDelivery from './HeaderDelivery';

export default {

    components: {
        SlideMenuCollapse,
        SlideMenuItem,
        PlusIcon,
        HeaderMainMobileAccount,
        HeaderMainMobileSearch,
        HeaderDelivery,
    },

    data() {
        return {
            openCollapseIndex: null,
        };
    },

    computed: {
        navItems: {
            get() {
                return this.$store.getters['content/mobileNavItems'];
            },
        },
        isOpen: {
            get() {
                return this.$store.getters['content/showSidebar'];
            },
            set(showSidebar) {
                this.$store.dispatch('content/setShowSidebar', showSidebar);
            },
        },
        background() {
            return this.$store.getters['settings/getByKey']('main_nav_background');
        },
    },

    watch: {
        $route() {
            this.close();
        },
    },

    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
            this.openCollapseIndex = null;
        },
        getComponentForItem(item) {
            return item.menu_items_id.grids && item.menu_items_id.grids.length > 0 ? SlideMenuCollapse : SlideMenuItem;
        },
        toggleCollapse(index) {
            this.openCollapseIndex = this.openCollapseIndex === index ? null : index;
        },
    },

};
</script>

<style lang="scss" scoped>
a {
    @apply no-underline;
}
.menu {
    @apply fixed bg-pink-100 transition-fast;
    z-index: 1001;
    left: -100%;
    top: 0;
    bottom: 0;
    &--open {
        left: 0;
    }
}
.overlay {
    @apply fixed transition-fast pointer-events-none opacity-0;
    background-color: #000;
    z-index: 1000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    &--shown {
        @apply opacity-50 pointer-events-auto;
    }
}
.link {
    @apply no-underline transition-fast;
    &:hover {
        @apply opacity-50;
    }
}
</style>
