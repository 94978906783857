<template>
    <div class="flex items-center justify-between h-16 text-black select-none">
        <HeaderNavLink
            :url="item.url"
            class="h-full font-bold text-lg flex items-center px-8 flex-grow no-underline hover-opacity"
        >
            {{ item.label }}
        </HeaderNavLink>
    </div>
</template>

<script>
import HeaderNavLink from './HeaderNavLink';

export default {

    components: {
        HeaderNavLink,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

};
</script>

<style lang="scss" scoped>
.hover-opacity {
    @apply transition-fast;
    &:hover {
        @apply opacity-50;
    }
}
</style>
