<script>
import VueSlickCarousel from 'vue-slick-carousel';
import ChevronLeftIcon from '~/static/images/chevron-left.svg';
import ChevronRightIcon from '~/static/images/chevron-right.svg';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import Slide from '~/components/pages/components/Slide.vue';

export default {
    components: {
        VueSlickCarousel,
        ChevronLeftIcon,
        ChevronRightIcon,
        Slide,
    },
    props: {
        component: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        isMobile() {
            return this.$store.getters['settings/isMobile'];
        },
        showControls() {
            return this.component.slides.length > 1 && this.component.show_controls;
        },
    },
    methods: {
        goToPrev() {
            this.$refs.carousel.prev();
        },
        goToNext() {
            this.$refs.carousel.next();
        },
    },
};
</script>

<template>
    <div :class="`style-${component.style} relative`">
        <VueSlickCarousel
            v-if="component.slides"
            ref="carousel"
            :arrows="false"
            :slides-to-show="isMobile && component.slides_to_show_mobile ? component.slides_to_show_mobile : component.slides_to_show"
            :slides-to-scroll="isMobile && component.slides_to_scroll_mobile ? component.slides_to_scroll_mobile : component.slides_to_scroll"
            :class="{'slick-multi': component.components_per_slide > 1, 'mt-alternate' : component.alternate_position }"
            :autoplay="component.autoplay || false"
            :autoplay-speed="(component.autoplay_speed || 3) * 1000"
            lazy-load="ondemand"
        >
            <Slide
                v-for="slide in component.slides"
                :key="`slide-${slide.id}`"
                :slide="slide"
            />
        </VueSlickCarousel>
        <div
            v-if="showControls"
            class="slide-navigation-button slide-navigation-button--left"
            @click="goToPrev"
        >
            <ChevronLeftIcon class="slide-navigation-button__icon" />
        </div>
        <div
            v-if="showControls"
            class="slide-navigation-button slide-navigation-button--right"
            @click="goToNext"
        >
            <ChevronRightIcon class="slide-navigation-button__icon" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.slide-navigation-button {
    @apply absolute text-pink-500 cursor-pointer transition-fast transition-property-color flex items-center;
    @apply top-0 bottom-0;
    &--left {
        @apply left-4;
    }
    &--right {
        @apply right-4;
    }
    &__icon {
        @apply w-8 md:w-12 h-8 md:h-12;
    }
    &:hover {
        @apply text-pink-500;
    }
}
</style>
