<script>
import ChevronRight from 'static/fonts/LineIcons/chevron-right.svg';
import HelpArticle from '~/components/pages/components/HelpArticle.vue';

export default {
    name: 'HelpCategory',
    components: { ChevronRight, HelpArticle },
    props: {
        component: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            activeArticleId: null,
        };
    },
    computed: {
        activeArticle() {
            const activeArticle = this.component.articles.find((article) => article.articles_id.id === this.activeArticleId);

            return activeArticle || this.component.articles[0];
        },
        activeArticleSlug() {
            if (this.activeArticle) {
                return this.activeArticle.articles_id.slug;
            }

            return null;
        },
        category() {
            return this.component;
        },
    },
};
</script>

<template>
    <div v-if="category">
        <h2 class="mt-4 mb-2 text-2xl">
            {{ category.name }}
        </h2>
        <div class="md:flex gap-8">
            <div class="md:w-1/4 mb-8">
                <nav class="md:flex flex-col gap-2">
                    <button
                        v-for="article in category.articles"
                        :key="article.articles_id.id"
                        class="px-4 py-2 flex justify-between items-center"
                        :class="activeArticleSlug === article.articles_id.slug ? 'bg-green-lightest' : 'bg-blue-100'"
                        @click="activeArticleId = article.articles_id.id"
                    >
                        {{ article.articles_id.title }}
                        <ChevronRight class="ml-8 w-4" />
                    </button>
                </nav>
            </div>
            <div class="md:w-3/4">
                <HelpArticle
                    v-if="activeArticle"
                    :article="activeArticle.articles_id"
                />
            </div>
        </div>
    </div>

</template>

<style scoped lang="scss">

</style>
