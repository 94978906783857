<template>
    <transition name="fade">
        <div v-show="isOpen">
            <div class="dropdown">

                <div
                    v-show="isBasketEmpty"
                    class="text-xs tracking-wide leading-none whitespace-nowrap font-bold select-none p-5"
                >
                    YOUR BASKET IS EMPTY
                </div>

                <div v-show="!isBasketEmpty">
                    <div class="text-xs tracking-wide leading-none font-bold text-grey-dark select-none p-5 pb-3">
                        YOUR BASKET
                    </div>
                    <div class="px-5">
                        <BasketItem
                            v-for="(item, index) in items"
                            :key="index"
                            :item="item"
                            @click="closeDropdown"
                        />
                    </div>
                    <nuxt-link
                        v-if="hasMoreThanThreeItems"
                        class="px-5 text-xs no-underline hover:text-primary transition-fast"
                        to="/checkout"
                    >
                        <span @click="closeDropdown">
                            and {{ additionalItemsCount }} more item{{ additionalItemsCount > 1 ? 's' : '' }}...
                        </span>
                    </nuxt-link>
                    <div class="p-5 pt-3 flex items-center">
                        <nuxt-link
                            to="/checkout"
                            class="no-underline inline-block mr-5"
                        >
                            <Button
                                text="Checkout"
                                @click="closeDropdown"
                            >
                                <template #icon>
                                    <CreditCardsIcon class="h-4 mr-3" />
                                </template>
                            </Button>
                        </nuxt-link>
                        <span class="font-semibold">£{{ basketTotalPrice }}</span>
                    </div>
                </div>

            </div>
            <div class="caret" />
        </div>
    </transition>
</template>

<script>
import BasketItem from '~/components/BasketItem';
import Button from '~/components/Button';
import CreditCardsIcon from '~/static/fonts/LineIcons/credit-cards.svg';

export default {

    components: {
        BasketItem,
        Button,
        CreditCardsIcon,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        items() {
            return this.$store.getters['basket/items'].slice(0, 3);
        },
        itemCount() {
            return this.$store.getters['basket/itemCount'];
        },
        additionalItemsCount() {
            return this.itemCount - 3;
        },
        hasMoreThanThreeItems() {
            return this.itemCount > 3;
        },
        isBasketEmpty() {
            return this.itemCount === 0;
        },
        basketTotalPrice() {
            return this.$store.getters['basket/totalPrice'];
        },
    },

    watch: {
        $route() {
            this.closeDropdown();
        },
    },

    methods: {
        closeDropdown() {
            this.$emit('close');
        },
    },

};
</script>

<style lang="scss" scoped>
.dropdown {
    border: 2px solid;
    top: 100%;
    right: 0;
    @apply border-primary bg-white absolute mt-3 rounded text-left shadow-md;
}
.caret {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 6px solid black;
    bottom: -12px;
    right: 50%;
    transform: translateX(50%);
    @apply border-primary;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}
.fade-enter-active,
.fade-leave-active {
    @apply transition-slow;
}
.fade-enter,
.fade-leave-to {
    @apply opacity-0;
    transform: translateY(10px);
}
</style>
