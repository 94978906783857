<template>
    <div>

        <h5 class="font-heading text-2xl mb-2 font-light">
            Let's get social
        </h5>
        <p class="text-xs">
            Are you a Bubblegum Balloons addict?<br>
            Tag your pictures with <strong>#bubblegumballoons</strong> to be in with a chance to feature in our gallery.
        </p>
        <ul class="social-icons">
            <li class="mr-2">
                <a
                    href="https://www.facebook.com/BubblegumBalloons/"
                    target="_blank"
                >
                    <FacebookIcon class="fill-white" />
                </a>
            </li>
            <li class="mr-2">
                <a
                    href="http://www.instagram.com/bubblegumballoons"
                    target="_blank"
                >
                    <InstagramIcon />
                </a>
            </li>
            <li class="mr-2">
                <a
                    href="http://www.pinterest.com/bubblegumballoons"
                    target="_blank"
                >
                    <PinterestIcon />
                </a>
            </li>
            <li>
                <a
                    href="https://twitter.com/3BubblegumGirls"
                    target="_blank"
                >
                    <TwitterIcon />
                </a>
            </li>
        </ul>

    </div>
</template>

<script>
import FacebookIcon from '~/static/fonts/LineIcons/facebook-filled.svg';
import InstagramIcon from '~/static/fonts/LineIcons/instagram-filled.svg';
import PinterestIcon from '~/static/fonts/LineIcons/pinterest.svg';
import TwitterIcon from '~/static/fonts/LineIcons/twitter-filled.svg';

export default {

    components: {
        FacebookIcon,
        InstagramIcon,
        PinterestIcon,
        TwitterIcon,
    },

};
</script>

<style lang="scss" scoped>
.social-icons {
    @apply flex justify-center text-white mt-3;
    a {
        @apply bg-grey-darker rounded-full flex justify-center items-center transition-property-bg transition-fast;
        width: 25px;
        height: 25px;
        &:hover {
            @apply bg-primary;
        }
        svg {
            width: 15px;
        }
    }
}
</style>
