<template>
    <div
        class="markdown"
        v-html="html"
    />
</template>

<script>
import { marked } from 'marked';

export default {
    props: {
        markdown: {
            type: String,
            default: '',
        },
    },
    computed: {
        html() {
            return marked(this.markdown);
        },
    },
};
</script>

<style scoped>

</style>
