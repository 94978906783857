<template>
    <button @click="openSlideMenu">
        <MenuIcon class="text-black h-8" />
    </button>
</template>

<script>
import MenuIcon from '~/static/fonts/LineIcons/menu.svg';

export default {

    components: {
        MenuIcon,
    },

    methods: {
        openSlideMenu() {
            this.$store.dispatch('content/setShowSidebar', true);
        },
    },

};
</script>

<style lang="scss" scoped>
</style>
