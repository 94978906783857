<template>
    <div
        class="card__new-banner"
        :style="{ color, backgroundColor }"
    >
        {{ product.banner_text }}
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },

    computed: {
        color() {
            if (this.product.banner_text_colour) {
                return this.product.banner_text_colour;
            }

            return null;
        },
        backgroundColor() {
            if (this.product.banner_background_colour) {
                return this.product.banner_background_colour;
            }

            return null;
        },
    },
};
</script>

<style scoped>
.card__new-banner {
    @apply text-xs leading-none absolute bg-pink-light/50 p-1 text-center text-white font-bold transition-slow top-0 right-0 left-0 bg-opacity-50;
}
</style>
