<template>
    <div
        v-if="price || offerPrice"
        class="inline-flex justify-center items-center"
    >
        <span
            v-if="showFrom"
            class="text-xs mr-1"
        >From </span>
        <span :class="offerName ? 'line-through' : ''">
            <Price :price="price" />
        </span>
        <span
            v-if="offerName"
            class="ml-1 text-red-light"
        >
            <Price :price="offerPrice" />
        </span>
        <span
            v-if="toHire"
            class="text-xs ml-2 uppercase"
        >
            To&nbsp;hire
        </span>
    </div>
</template>

<script>
import Price from './Price';

export default {
    components: {
        Price,
    },
    props: {
        offerName: {
            type: String,
            default: null,
        },
        offerPrice: {
            type: [String, Number],
            default: null,
        },
        price: {
            type: [String, Number],
            default: null,
        },
        showFrom: {
            type: Boolean,
            default: false,
        },
        toHire: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>

</style>
