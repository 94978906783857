<template>
    <div>

        <nuxt-link
            v-if="isLoggedIn"
            class="flex items-center font-semibold no-underline"
            to="/account/details"
        >

            <UserIcon class="user-icon mr-2" /> Your account
        </nuxt-link>
        <button
            v-else
            class="flex items-center font-semibold"
            @click="openLoginModal"
        >
            <UserIcon class="user-icon mr-2" /> Sign in
        </button>

    </div>
</template>

<script>
import UserIcon from '~/static/fonts/LineIcons/user.svg';

export default {

    components: {
        UserIcon,
    },

    computed: {
        isLoggedIn: {
            get() {
                return this.$store.getters['user/isLoggedIn'];
            },
        },
    },

    methods: {
        openLoginModal() {
            this.$store.dispatch('content/setShowSidebar', false);
            this.$modal.show('auth-modal');
        },
    },

};
</script>

<style lang="scss" scoped>
.user-icon {
    height: 22px;
}
</style>
