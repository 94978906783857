<template>
    <div
        v-on-clickaway="closeDropdown"
        class="inline-block relative"
    >

        <div
            class="relative"
            @click="openDropdown"
        >
            <Input
                v-model="query"
                :focused="focused"
                placeholder="Search for something..."
                @input="searchDebounced"
                @enter="viewFullSearchResults"
            />
            <SearchIcon
                v-show="!isLoading"
                class="icon"
            />
            <Spinner
                v-show="isLoading"
                :line-size="2"
                size="18"
                class="icon"
            />
        </div>

        <HeaderMainSearchResultsDropdown
            :results="results"
            :is-open="isDropdownOpen"
            :query="query"
            @close="closeDropdown"
        />

    </div>
</template>

<script>
import debounce from 'debounce';
import { mixin as clickaway } from 'vue-clickaway';
import HeaderMainSearchResultsDropdown from '~/components/HeaderMainSearchResultsDropdown';
import Spinner from '~/components/Spinner';
import Input from '~/components/Input';
import SearchIcon from '~/static/fonts/LineIcons/magnifier.svg';

export default {

    components: {
        HeaderMainSearchResultsDropdown,
        Spinner,
        Input,
        SearchIcon,
    },

    mixins: [
        clickaway,
    ],

    data() {
        return {
            canDropDownOpen: false,
            isDropdownOpen: false,
            query: '',
            isLoading: false,
            results: {
                products: {
                    data: [],
                    total: 0,
                },
                categories: {
                    data: [],
                    total: 0,
                },
                pages: {
                    data: [],
                    total: 0,
                },
            },
            focused: false,
            controller: null,
        };
    },

    watch: {
        $route() {
            this.closeDropdown();
        },
    },

    created() {
        this.$nuxt.$on('focus-search', this.focusInput);
    },

    methods: {
        searchDebounced: debounce(function _(query) {
            if (this.controller) {
                this.controller.abort();
            }

            this.controller = new AbortController();

            const searchQuery = query.trim();

            if (searchQuery.length) {
                this.isLoading = true;
                const self = this;

                this.$axios.$get('/search', {
                    signal: this.controller.signal,
                    params: {
                        query: searchQuery,
                    },
                })
                    .then((results) => {
                        self.results = results;
                        self.canDropDownOpen = true;
                        self.openDropdown();
                    })
                    .catch((error) => {
                        // Do nothing
                    })
                    .finally(() => {
                        self.isLoading = false;
                    });
            } else {
                this.closeDropdown();
            }
        }, 100),
        openDropdown() {
            this.isDropdownOpen = this.canDropDownOpen;
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        },
        focusInput() {
            this.focused = false;
            this.$nextTick(() => {
                this.focused = true;
            });
        },
        viewFullSearchResults() {
            this.closeDropdown();
            this.$router.push(`/search?query=${this.query}`);
        },
    },

};
</script>

<style scoped>
.icon {
    height: 18px;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    @apply absolute pointer-events-none mr-2;
}
</style>
