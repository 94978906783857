<template>
    <div class="flex-grow">

        <label
            v-if="label"
            class="select-none"
        >{{ label }}</label>
        <input
            ref="input"
            :value="value"
            :class="inputClasses"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :tabIndex="tabIndex"
            :autocomplete="autocomplete"
            :maxlength="maxlength"
            :min="min"
            :max="max"
            :step="step"
            @input="handleInput($event)"
            @keyup.enter="handleEnter"
            @keyup.tab="handleEnter"
            @focus="focus"
            @blur="blur"
        >

        <transition name="slide-in-out">
            <div
                v-if="!!$slots['errors'] && invalid"
                class="text-sm mt-2 text-red-light"
            >
                <slot name="errors" />
            </div>
        </transition>

        <transition name="slide-in-out">
            <div
                v-if="success"
                class="text-sm mt-2 text-green-light"
            >
                <slot name="success" />
            </div>
        </transition>

    </div>
</template>

<script>
export default {

    props: {
        label: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        autocomplete: {
            type: String,
            default: 'on',
        },
        value: {
            type: [String, Number],
            default: '',
            required: false,
        },
        canBeTabbedTo: {
            type: Boolean,
            default: true,
        },
        focused: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        maxlength: {
            type: Number,
            default: 524288,
        },
        min: {
            type: Number,
            default: null,
        },
        max: {
            type: Number,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
    },

    computed: {
        isEmpty() {
            return this.value === null || this.value.length === 0;
        },
        tabIndex() {
            return this.canBeTabbedTo ? '0' : '-1';
        },
        inputClasses() {
            let classes = '';
            if (this.invalid) {
                classes += ' invalid';
            }
            return classes;
        },
    },

    watch: {
        focused(newValue) {
            if (newValue) {
                this.focusInput();
            }
        },
    },

    mounted() {
        if (this.focused) {
            this.focusInput();
        }
    },

    methods: {
        handleInput(event) {
            this.$emit('input', event.target.value);
        },
        handleEnter() {
            this.$emit('enter');
        },
        focusInput() {
            // this.$nextTick(() => this.$refs.input.focus());
        },
        blur() {
            this.$emit('blur');
        },
        focus() {
            this.$emit('focus');
        },
    },

};
</script>

<style lang="scss" scoped>
label {
    @apply block text-xs uppercase tracking-wide font-semibold text-grey-darker mb-1;
}
input {
    border: 2px solid;
    @apply bg-grey-lighter rounded border-grey-lighter transition-fast w-full px-3 py-2;
    height: 2.75rem;
    &:focus,
    &.not-empty {
        @apply border-primary outline-none;
    }
    &.invalid {
        @apply border-red-light;
    }
    &::placeholder {
        @apply text-sm text-grey opacity-100;
    }
}
.slide-in-out-enter-active,
.slide-in-out-leave-active {
    @apply transition-slow;
}
.slide-in-out-enter,
.slide-in-out-leave-to {
    @apply opacity-0 absolute;
    transform: translateX(10px);
}
</style>
