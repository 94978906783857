import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _eca5c910 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _27f462cc = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _3fcc40b0 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _748a0284 = () => interopDefault(import('../pages/help/_category.vue' /* webpackChunkName: "pages/help/_category" */))
const _3906a318 = () => interopDefault(import('../pages/help/_category/_article.vue' /* webpackChunkName: "pages/help/_category/_article" */))
const _15924e08 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _4991d1d7 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _b42a323e = () => interopDefault(import('../pages/account/addresses.vue' /* webpackChunkName: "pages/account/addresses" */))
const _5ce1697e = () => interopDefault(import('../pages/account/details.vue' /* webpackChunkName: "pages/account/details" */))
const _77f80aa8 = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _1ff57ab6 = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _65e45f2c = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _62e3fb2a = () => interopDefault(import('../pages/newsletter/unsubscribe.vue' /* webpackChunkName: "pages/newsletter/unsubscribe" */))
const _184fcbc0 = () => interopDefault(import('../pages/checkout/success/_orderId.vue' /* webpackChunkName: "pages/checkout/success/_orderId" */))
const _2815bb72 = () => interopDefault(import('../pages/newsletter/manage/_id.vue' /* webpackChunkName: "pages/newsletter/manage/_id" */))
const _064295c6 = () => interopDefault(import('../pages/categories/_slug.vue' /* webpackChunkName: "pages/categories/_slug" */))
const _132c6960 = () => interopDefault(import('../pages/pages/_slug.vue' /* webpackChunkName: "pages/pages/_slug" */))
const _8b15de8e = () => interopDefault(import('../pages/products/_id/_slug.vue' /* webpackChunkName: "pages/products/_id/_slug" */))
const _3ee5c5f3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _27da3eee = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _2d598900 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _eca5c910,
    name: "checkout"
  }, {
    path: "/contact-us",
    component: _27f462cc,
    name: "contact-us"
  }, {
    path: "/help",
    component: _3fcc40b0,
    name: "help",
    children: [{
      path: ":category?",
      component: _748a0284,
      name: "help-category",
      children: [{
        path: ":article?",
        component: _3906a318,
        name: "help-category-article"
      }]
    }]
  }, {
    path: "/reset-password",
    component: _15924e08,
    name: "reset-password"
  }, {
    path: "/search",
    component: _4991d1d7,
    name: "search"
  }, {
    path: "/account/addresses",
    component: _b42a323e,
    name: "account-addresses"
  }, {
    path: "/account/details",
    component: _5ce1697e,
    name: "account-details"
  }, {
    path: "/account/login",
    component: _77f80aa8,
    name: "account-login"
  }, {
    path: "/account/orders",
    component: _1ff57ab6,
    name: "account-orders"
  }, {
    path: "/checkout/payment",
    component: _65e45f2c,
    name: "checkout-payment"
  }, {
    path: "/newsletter/unsubscribe",
    component: _62e3fb2a,
    name: "newsletter-unsubscribe"
  }, {
    path: "/checkout/success/:orderId?",
    component: _184fcbc0,
    name: "checkout-success-orderId"
  }, {
    path: "/newsletter/manage/:id?",
    component: _2815bb72,
    name: "newsletter-manage-id"
  }, {
    path: "/categories/:slug?",
    component: _064295c6,
    name: "categories-slug"
  }, {
    path: "/pages/:slug?",
    component: _132c6960,
    name: "pages-slug"
  }, {
    path: "/products/:id?/:slug?",
    component: _8b15de8e,
    name: "products-id-slug"
  }, {
    path: "/",
    component: _3ee5c5f3,
    name: "index"
  }, {
    path: "/:slug",
    component: _27da3eee,
    name: "slug"
  }, {
    path: "/*",
    component: _2d598900,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
