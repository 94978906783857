<template>

    <div class="bg-pink-200 text-white">
        <div class="container mx-auto py-5 flex lg:flex-nowrap flex-wrap items-center justify-center lg:justify-start">
            <nuxt-link to="/">
                <img
                    v-lazy="'/images/logo-footer.png'"
                    class="logo lg:mr-10 mb-5 lg:mb-0"
                    alt="Bubblegum Balloons Logo"
                >
            </nuxt-link>
            <div class="text-xs text-center lg:text-left p-4 lg:p-0">
                Copyright &copy; {{ year }} Bubblegum Balloons Limited.
                <nuxt-link to="/terms-and-conditions">
                    Terms & Conditions
                </nuxt-link> |
                <nuxt-link to="/privacy-policy">
                    Privacy Policy
                </nuxt-link>.
                <div class="inline-block lg:inline">
                    Company Registration Number: 08614104.
                    VAT Registration No: 195993829.
                    <br>Registered Address: Bubblegum Balloons, Unit 16, Armstrong Mall,
                    Southwood Business Park, Farnborough, Hampshire, GU14 0NR
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import moment from 'moment';

export default {
    computed: {
        year() {
            return moment().year();
        },
    },
};
</script>

<style scoped>
.logo {
    height: 50px;
}
</style>
