import { render, staticRenderFns } from "./SlideMenu.vue?vue&type=template&id=1fc5b32a&scoped=true&"
import script from "./SlideMenu.vue?vue&type=script&lang=js&"
export * from "./SlideMenu.vue?vue&type=script&lang=js&"
import style0 from "./SlideMenu.vue?vue&type=style&index=0&id=1fc5b32a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fc5b32a",
  null
  
)

export default component.exports