export default {

    user: (state) => state.user,
    id: (state) => state.user.id,
    firstName: (state) => state.user.first_name,
    lastName: (state) => state.user.last_name,
    email: (state) => state.user.email,
    phone: (state) => state.user.phone,
    hasUsedNewsletterDiscount: (state) => state.user.has_used_newsletter_discount,
    isLoggedIn: (state) => state.isLoggedIn,
    canPayByInvoice: (state) => state.user.can_pay_by_invoice,
};
