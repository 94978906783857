<script>
import HeaderNavLink from '@/components/HeaderNavLink.vue';

export default {
    components: { HeaderNavLink },
    props: {
        component: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<template>
    <div class="flex flex-col gap-2">
        <h5
            v-if="component.title"
            class="text-base font-bold"
        >
            {{ component.title }}
        </h5>
        <HeaderNavLink
            v-for="(link, index) in component.links"
            :key="index"
            :url="link.url"
        >
            {{ link.label }}
        </HeaderNavLink>
    </div>
</template>

<style scoped lang="scss">

</style>
