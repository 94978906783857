import handleException from '../../plugins/error-handler.client';

export default {

    /**
     * Resets the basket and removes the basket id from localstorage.
     */
    reset({ commit }) {
        this.$cookies.remove('basket');
        commit('reset');
    },

    /**
     * Sets the basket. Used after fetching basket from API.
     */
    setBasket({ commit }, order) {
        commit('updateBasket', order);
    },

    /**
     * Used to add, remove or update a basket item.
     * Adds the basket id to local storage.
     */
    async updateItem({ dispatch, getters, rootGetters }, { productId, productVariationsCompound = null, productTextVariation, productTextVariationLine2, customerImageUrl, canvasPreview, quantity, fontId }) {
        let basketId;
        const basket = this.$cookies.get('basket');

        if (basket) {
            basketId = basket.id;
        }

        const order = await this.$axios.$post('basket/items', {
            order_id: basketId,
            product_id: productId,
            product_variations_compound: productVariationsCompound,
            product_text_variation: productTextVariation,
            product_variation_text_line_2: productTextVariationLine2,
            customer_image_url: customerImageUrl,
            canvas_preview: canvasPreview,
            quantity,
            font_id: fontId,
        });
        dispatch('setBasketCookie', order.id);
        dispatch('setBasket', order);

        // if mobile detect says mobile, apply a discount code
        if (!getters.discountCode && rootGetters['settings/isMobile'] && rootGetters['settings/getByKey']('mobile_discount_code')) {
            dispatch('applyDiscountCode', rootGetters['settings/getByKey']('mobile_discount_code'));
        }

        return order;
    },

    getOrderById({ commit }, payload) {
        return this.$axios.$get(`orders/${payload.orderId}`).then((order) => commit('updateOrder', order)).catch((error) => handleException(error));
    },

    async applyDiscountCode({ commit, getters, dispatch }, code) {
        const basket = await this.$axios.$post(`/basket/${getters.id}/discount-code?code=${code}`);
        await dispatch('setBasket', basket);
    },

    setBasketCookie(context, basketId) {
        this.$cookies.set(
            'basket',
            {
                id: basketId,
            },
            {
                path: '/',
                maxAge: 60 * 60 * 24 * 7,
            }
        );
    },

};
