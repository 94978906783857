<template>
    <client-only>

        <spinner
            :size="size"
            :line-size="lineSize"
            :line-bg-color="colors.primary"
            :line-fg-color="colors.white"
            :spacing="0"
        />

    </client-only>
</template>

<script>
const { colors } = require('../tailwind.config.js').theme;

export default {

    props: {
        lineSize: {
            type: Number,
            default: null,
        },
        size: {
            type: String,
            default: null,
        },
    },

    computed: {
        colors() {
            return colors;
        },
    },

};
</script>

<style lang="scss" scoped>
</style>
