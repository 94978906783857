<template>
    <button @click="openSearch">
        <SearchIcon class="text-black h-6" />
        <slot />
    </button>
</template>

<script>
import SearchIcon from '~/static/fonts/LineIcons/magnifier.svg';

export default {

    components: {
        SearchIcon,
    },

    methods: {
        openSearch() {
            this.$nuxt.$emit('open-mobile-search');
            this.$store.dispatch('content/setShowSidebar', false);
        },
    },

};
</script>

<style lang="scss" scoped>
</style>
