<script>
import Button from '~/components/pages/components/Button.vue';
import Image from '~/components/pages/components/Image.vue';
import Markdown from '~/components/pages/components/Markdown.vue';
import Grid from '~/components/pages/Grid.vue';
import Links from '~/components/pages/components/Links.vue';

const components = {
    buttons: Button,
    images: Image,
    markdown: Markdown,
    grids: Grid,
    links: Links,
};

export default {
    props: {
        component: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        getComponent() {
            return components[this.component.collection];
        },
    },
};
</script>

<template>
    <component
        :is="getComponent"
        :component="component.item"
    />
</template>

<style scoped lang="scss">

</style>
