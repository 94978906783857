<template>
    <div
        :class="{ 'search--open' : isOpen }"
        class="search overflow-y-scroll"
    >

        <div
            class="overlay"
            @click="close"
        />

        <div class="relative z-50 h-px mt-8 mx-5 w-full">
            <div class="text-2xl text-white font-bold mb-2 leading-none flex justify-between items-end">
                <span>Search</span>
                <span
                    class="text-base"
                    @click="close"
                >Close</span>
            </div>
            <div
                class="relative"
                @click="openDropdown"
            >
                <input
                    ref="input"
                    v-model="query"
                    :tabindex="[ isOpen ? '1' : '-1' ]"
                    type="search"
                    class="w-full"
                    placeholder="Search for something..."
                    @input="searchDebounced($event.target.value)"
                    @keyup.enter="viewFullSearchResults"
                >
                <Spinner
                    v-show="isLoading"
                    :line-size="2"
                    size="18"
                    class="icon"
                />
            </div>

            <HeaderMainSearchResultsDropdown
                :results="results"
                :is-open="isDropdownOpen"
                :query="query"
                class="z-50"
                centered
                @close="close"
            />

        </div>

    </div>
</template>

<script>
import debounce from 'debounce';
import HeaderMainSearchResultsDropdown from '~/components/HeaderMainSearchResultsDropdown';
import Spinner from '~/components/Spinner';

export default {

    components: {
        HeaderMainSearchResultsDropdown,
        Spinner,
    },

    data() {
        return {
            isOpen: false,
            canDropDownOpen: false,
            isDropdownOpen: false,
            query: '',
            isLoading: false,
            results: {
                products: {
                    data: [],
                    total: 0,
                },
                categories: {
                    data: [],
                    total: 0,
                },
                pages: {
                    data: [],
                    total: 0,
                },
            },
            controller: null,
        };
    },

    created() {
        this.registerEvents();
    },

    methods: {
        registerEvents() {
            this.$nuxt.$on('open-mobile-search', this.open);
        },
        open() {
            this.isDropdownOpen = false;
            this.canDropDownOpen = false;
            document.body.classList.add('modal-open');
            this.query = '';
            this.isOpen = true;
            this.$nextTick(() => this.$refs.input.focus());
        },
        close() {
            this.isOpen = false;
            document.body.classList.remove('modal-open');
            this.closeDropdown();
        },
        openDropdown() {
            this.isDropdownOpen = this.canDropDownOpen;
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        },
        searchDebounced: debounce(function _(query) {
            if (this.controller) {
                this.controller.abort();
            }

            this.controller = new AbortController();

            const searchQuery = query.trim();

            if (searchQuery.length) {
                this.isLoading = true;
                const self = this;
                this.$axios.$get('/search', {
                    signal: this.controller.signal,
                    params: {
                        query: searchQuery,
                    },
                })
                    .then((results) => {
                        self.results = results;
                        self.canDropDownOpen = true;
                        self.openDropdown();
                    })
                    .catch((error) => {
                        // Do nothing
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            } else {
                this.closeDropdown();
            }
        }, 500),
        viewFullSearchResults() {
            this.$router.push(`/search?query=${this.query}`);
            this.close();
        },
    },

};
</script>

<style lang="scss" scoped>
.search {
    @apply fixed opacity-0 transition-fast pointer-events-none flex justify-center z-50;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    .overlay {
        @apply absolute h-full w-full opacity-75 z-40;
        background-color: #000;
    }
    input {
        @apply relative block rounded py-3 px-4 text-lg;
    }
    &--open {
        @apply opacity-100 pointer-events-auto;
    }
}
.icon {
    height: 18px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @apply absolute pointer-events-none pr-8;
}
</style>
