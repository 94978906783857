<template>
    <div class="text-black select-none">

        <div class="flex items-center justify-between h-16">

            <HeaderNavLink
                :url="item.url"
                class="font-bold text-lg px-8"
            >
                {{ item.label }}
            </HeaderNavLink>

            <div
                class="h-full w-16 flex items-center justify-center cursor-pointer hover-opacity"
                @click="toggleCollapse"
            >
                <MinusIcon
                    v-show="isOpen"
                    class="flex w-6 h-6"
                />
                <PlusIcon
                    v-show="!isOpen"
                    class="flex w-6 h-6"
                />
            </div>

        </div>

        <div
            v-if="isOpen"
            ref="childrenWrapper"
            class="overflow-hidden transition-property-max-height transition-fast bg-pink-100"
        >
            <Grid
                v-for="(grid, index) in item.grids"
                :key="`section-${index}`"
                :grid="grid.grids_id"
                class="text-black py-4"
            />
        </div>
    </div>
</template>

<script>
import MinusIcon from '~/static/fonts/LineIcons/minus.svg';
import PlusIcon from '~/static/fonts/LineIcons/plus.svg';
import HeaderNavLink from './HeaderNavLink';
import HeaderNavSection from './HeaderNavSection';
import Grid from '@/components/pages/Grid.vue';

export default {

    components: {
        Grid,
        HeaderNavLink,
        MinusIcon,
        PlusIcon,
        HeaderNavSection,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            childrenLength: 5,
            childrenWrapper: null,
        };
    },

    computed: {
        childrenWrapperMaxHeight() {
            if (this.childrenWrapper && this.isOpen) {
                return `${this.childrenWrapper.scrollHeight}px`;
            }
            return 0;
        },
        limitedChildren() {
            return this.item.children;
        },
        hasMoreChildren() {
            return this.item.children.length > this.childrenLength;
        },
    },

    mounted() {
        this.childrenWrapper = this.$refs.childrenWrapper;
    },

    methods: {
        toggleCollapse() {
            this.$emit('toggle-collapse');
        },
    },

};
</script>

<style lang="scss" scoped>
.transition-property-max-height {
    transition-property: max-height;
}
.hover-opacity {
    @apply transition-fast;
    &:hover {
        @apply opacity-50;
    }
}
</style>
