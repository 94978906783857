<template>
    <div
        v-if="activeNoticeCarouselItem"
        :style="{ background: getByKey('site_notice_background') }"
        class="site-notice text-white bg-pink-light p-3 text-xs sm:text-base"
    >
        <div class="container mx-auto flex justify-between">
            <div v-html="activeNoticeCarouselItem" />

            <div class="hidden md:block">
                Explore our <a
                    href="https://bubblegumballoons.com/balloon-installations"
                    class="underline"
                >installations</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            interval: null,
        };
    },
    computed: {
        ...mapGetters('settings', [
            'getByKey',
            'activeNoticeCarouselItem',
        ]),
    },
    mounted() {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.$store.dispatch('settings/incrementNoticeCarouselIndex'), 5000);
    },
};
</script>
