export default {

    updateSettings(state, payload) {
        state.settings = payload.settings;
    },

    SET_NEXT_DELIVERY_DATE(state, value) {
        state.nextDeliveryDate = value;
    },

    SET_NEXT_DELIVERY_CUT_OFF(state, value) {
        state.nextDeliveryCutOff = value;
    },

    SET_NEXT_DELIVERY_CUT_OFF_INTERVAL(state, value) {
        state.nextDeliveryCutOffInterval = value;
    },

    SET_NEXT_DELIVERY_CUT_OFF_COUNTDOWN(state, value) {
        state.nextDeliveryCutOffCountdown = value;
    },

    SET_NOTICE_CAROUSEL_INDEX(state, value) {
        state.noticeCarouselIndex = value;
    },

    SET_COLLECTION_HOLIDAYS(state, value) {
        state.collectionHolidays = value;
    },

    SET_DELIVERY_HOLIDAYS(state, value) {
        state.deliveryHolidays = value;
    },

    SET_AVAILABLE_RATES(state, value) {
        state.availableRates = value;
    },

    SET_IS_MOBILE(state, value) {
        state.isMobile = value;
    },
};
