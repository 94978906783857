<template>
    <nuxt-link
        v-if="url && internal"
        :to="cleanUrl"
        @click.native="hideMenu"
    >
        <slot />
    </nuxt-link>
    <a
        v-else-if="url"
        :href="url"
    >
        <slot />
    </a>
    <span v-else>
        <slot />
    </span>
</template>
<script>
export default {
    props: {
        text: {
            type: String,
            default: '',
        },
        url: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            internalStrings: [
                '/products',
                '/categories',
                '/help',
                '/balloon-installations',
                'https://bubblegumballoons.com',
            ],
            externalStrings: [
                '/blog',
            ],
        };
    },
    computed: {
        internal() {
            return this.internalStrings.some((pattern) => this.url.includes(pattern))
                && !this.externalStrings.some((pattern) => this.url.includes(pattern));
        },
        cleanUrl() {
            if (this.internal) {
                return this.url.replace(/https:\/\/bubblegumballoons.com/, '');
            }

            return this.url;
        },
    },
    methods: {
        hideMenu() {
            this.$store.dispatch('content/setMegaMenuOpen', false);
        },
    },
};
</script>
