export default {
    setProductVariations: ({ commit }, variations) => {
        commit('SET_PRODUCT_VARIATIONS', variations);
    },

    setText: ({ commit }, text) => {
        commit('SET_TEXT', text);
    },

    setTextLine2: ({ commit }, textLine2) => {
        commit('SET_TEXT_LINE_2', textLine2);
    },

    setFonts: ({ commit }, fonts) => {
        commit('SET_FONTS', fonts);
    },

    setFontId: ({ commit }, fontId) => {
        commit('SET_FONT_ID', fontId);
    },

    setFontFamily: ({ commit }, fontFamily) => {
        commit('SET_FONT_FAMILY', fontFamily);
    },

    setCustomerImageUrl: ({ commit }, customerImageUrl) => {
        commit('SET_CUSTOMER_IMAGE_URL', customerImageUrl);
    },

    setCustomerImageWidth: ({ commit }, customerImageWidth) => {
        commit('SET_CUSTOMER_IMAGE_WIDTH', customerImageWidth);
    },

    setCustomerImageHeight: ({ commit }, customerImageHeight) => {
        commit('SET_CUSTOMER_IMAGE_HEIGHT', customerImageHeight);
    },

    setCustomerImagePreview: ({ commit }, customerImagePreview) => {
        commit('SET_CUSTOMER_IMAGE_PREVIEW', customerImagePreview);
    },

    setSelectedVariations: ({ commit }, selectedVariations) => {
        commit('SET_SELECTED_VARIATIONS', selectedVariations);
    },

    addSelectedVariation: ({ commit }, selectedVariation) => {
        commit('ADD_SELECTED_VARIATION', selectedVariation);
    },

    removeSelectedVariation: ({ commit }, selectedVariationId) => {
        commit('REMOVE_SELECTED_VARIATION', selectedVariationId);
    },

    setCanvasPreview: ({ commit }, canvasPreview) => {
        commit('SET_CANVAS_PREVIEW', canvasPreview);
    },

    setShowStockNotification: ({ commit }, showStockNotification) => {
        commit('SET_SHOW_STOCK_NOTIFICATION', showStockNotification);
    },

    setShowProductCustomisationImage: ({ commit }, showProductCustomisationImage) => {
        commit('SET_SHOW_PRODUCT_CUSTOMISATION_IMAGE', showProductCustomisationImage);

        if (showProductCustomisationImage) {
            commit('SET_ACTIVE_VARIATION_IMAGE', null);
            commit('SET_IS_VARIATION_IMAGE', false);
        }
    },

    setCustomisationImage: ({ commit }, customisationImage) => {
        commit('SET_CUSTOMISATION_IMAGE', customisationImage);
    },

    setDefaultImage: ({ commit }, defaultImage) => {
        commit('SET_DEFAULT_IMAGE', defaultImage);
    },

    setIsVariationImage: ({ commit }, isVariationImage) => {
        commit('SET_IS_VARIATION_IMAGE', isVariationImage);
    },

    setIsBuyButtonLoading: ({ commit }, isBuyButtonLoading) => {
        commit('SET_IS_BUY_BUTTON_LOADING', isBuyButtonLoading);
    },

    setTotalPrice: ({ commit }, totalPrice) => {
        commit('SET_TOTAL_PRICE', totalPrice);
    },

    setQuantity: ({ commit }, quantity) => {
        commit('SET_QUANTITY', quantity);
    },

    setActiveVariationImage: ({ commit }, activeVariationImage) => {
        commit('SET_ACTIVE_VARIATION_IMAGE', activeVariationImage);
    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
};
