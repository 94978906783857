<template>
    <div>

        <div class="container mx-auto flex items-center py-5">

            <div class="w-1/3">
                <HeaderDelivery />
            </div>

            <div class="w-1/3 flex justify-center">
                <nuxt-link to="/">

                    <img
                        v-lazy="'https://cdn.bubblegumballoons.com/logo.svg'"
                        class="logo"
                        alt="Bubblegum Balloons Logo"
                        width="480"
                        height="45"
                    >
                </nuxt-link>
            </div>

            <div class="w-1/4 flex items-center justify-end ml-auto gap-3">
                <HeaderMainSearch class="w-full" />
                <HeaderMainMyAccount v-if="isLoggedIn" />
                <div
                    v-else
                    class="flex items-center hover:text-primary cursor-pointer font-semibold transition-fast"
                    @click="openLoginModal"
                >
                    <UserIcon class="user-icon" />
                    <span class="text-xs sr-only">SIGN IN</span>
                </div>
                <HeaderMainBasket />
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderMainMyAccount from '~/components/HeaderMainMyAccount';
import HeaderMainBasket from '~/components/HeaderMainBasket';
import HeaderMainSearch from '~/components/HeaderMainSearch';
import UserIcon from '~/static/fonts/LineIcons/user.svg';
import HeaderDelivery from './HeaderDelivery';

export default {

    components: {
        HeaderMainMyAccount,
        HeaderMainBasket,
        HeaderMainSearch,
        HeaderDelivery,
        UserIcon,
    },

    computed: {
        isLoggedIn: {
            get() {
                return this.$store.getters['user/isLoggedIn'];
            },
        },
        ...mapGetters('settings', [
            'getByKey',
        ]),
    },

    methods: {
        openLoginModal() {
            this.$modal.show('auth-modal');
        },
    },

};
</script>

<style lang="scss" scoped>
.logo {
    @apply block transition-fast;
    &:hover {
        @apply opacity-75;
    }
}
.user-icon {
    height: 22px;
}
</style>
