<template>
    <div
        class="h-full flex flex-col items-start space-y-2"
        :class="{'opacity-50': !product.in_stock}"
    >

        <nuxt-link
            :to="`/products/${product.id}/${product.slug}`"
            @click.native="trackClick"
        >
            <div
                class="card card--hover"
                @mouseover="hover = true"
                @mouseleave="hover = false"
            >

                <img
                    :key="`${product.id}-hover`"
                    v-lazy="secondaryImageUrl"
                    :alt="imageAlt"
                    class="card__image__hover"
                    :class="{ 'opacity-100': hover }"
                >

                <img
                    :key="product.id"
                    v-lazy="imageUrl"
                    :alt="imageAlt"
                    class="card__image"
                >

                <ProductBanner
                    v-if="product.banner_text"
                    :product="product"
                    class="card__new-banner"
                />

            </div>
        </nuxt-link>

        <div class="text-center flex-grow w-full hidden lg:inline">
            <nuxt-link
                ref="name"
                :class="{ 'text-sm' : smallTitle }"
                :to="`/products/${product.id}/${product.slug}`"
                class="font-semibold no-underline hover:text-primary transition-fast transition-property-color"
                @click.native="trackClick"
            >
                <span class="lg:inline hidden">{{ product.name }}</span>
                <span class="lg:hidden inline text-sm">{{ product.short_name }}</span>
            </nuxt-link>
        </div>

        <div class="items-center w-full">

            <div class="lg:text-2xl w-full text-center font-semibold leading-none mb-2">
                <nuxt-link
                    :to="`/products/${product.id}/${product.slug}`"
                    class="no-underline hover:text-primary transition-fast transition-property-color"
                    @click.native="trackClick"
                >
                    <OfferPrice
                        :price="product.from_price"
                        :offer-price="product.offer_price"
                        :offer-name="product.offer_name"
                        :show-from="product.has_variations || product.is_enquiry"
                        :to-hire="product.is_hire"
                        class="text-primary"
                    />
                </nuxt-link>
            </div>

            <client-only>
                <div
                    v-if="availableForDelivery"
                    class="w-full"
                >
                    <div
                        v-if="!product.in_stock"
                        class="flex items-center px-4 h-10 justify-center text-red-light font-bold"
                    >
                        <span>
                            Sold out
                        </span>
                    </div>
                </div>
            </client-only>

        </div>

    </div>
</template>

<script>
import BasketIcon from '~/static/fonts/LineIcons/shopping-basket.svg';
import Button from '~/components/Button';
import ProductQuantityInput from '~/components/ProductQuantityInput';
import handleException from '../plugins/error-handler.client';
import ProductBanner from '~/components/ProductBanner';
import OfferPrice from './OfferPrice';

export default {

    components: {
        BasketIcon,
        Button,
        ProductQuantityInput,
        ProductBanner,
        OfferPrice,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },
        position: {
            type: Number,
            required: false,
            default: 0,
        },
        list: {
            type: String,
            required: false,
            default: '',
        },
        availableForDelivery: {
            type: Boolean,
            default: true,
        },
        category: {
            type: String,
            required: false,
            default: '',
        },
    },

    data() {
        return {
            smallTitle: false,
            isBuyButtonloading: false,
            hover: false,
        };
    },

    computed: {
        buyButtonText() {
            if (!this.product.in_stock) {
                return 'Sold out';
            }
            return this.product.button_label || 'Buy';
        },
        quantityInBasket() {
            const basketItems = this.$store.getters['basket/items'];
            const currentProduct = basketItems.find((item) => item.product.id === this.product.id);
            return currentProduct ? currentProduct.quantity : 0;
        },
        imageUrl() {
            if (this.hover && this.secondaryImageUrl) {
                return this.secondaryImageUrl;
            }

            return this.product.image ? this.product.image.sizes.large : this.product.images[0].sizes.large;
        },
        imageAlt() {
            return this.product.image ? this.product.image.alt : this.product.name;
        },
        secondaryImageUrl() {
            return this.product.hover_image ? this.product.hover_image.sizes.large : this.product.images?.[1]?.sizes?.large;
        },
        hasCheckedPostcode() {
            if (this.product.check_postcode) {
                return this.$store.getters['checkout/checkProductAvailable'](this.product.shipping_class_id);
            }

            return false;
        },
    },

    mounted() {
        // make long titles smaller
        this.smallTitle = parseInt(this.$refs.name.$el.offsetHeight, 10) > parseInt(window.getComputedStyle(this.$refs.name.$el).lineHeight, 10);

        this.$bus.$emit('product-impression', {
            product: this.product,
            list: this.list,
            position: this.position,
            category: this.category || this.product.category,
        });

        this.$ga.ecommerce.addProduct({
            id: this.product.sku,
            name: this.product.name,
            category: this.product.category,
        });
        this.$ga.ecommerce.addImpression({
            id: this.product.sku,
            name: this.product.name,
            category: this.product.category,
            position: this.position,
            list: this.list,
        });
    },

    methods: {
        handleAddToBasketClick() {
            if (this.product.has_variations) {
                this.trackClick();
                this.$router.push(`/products/${this.product.id}/${this.product.slug}`);
            } else {
                this.isBuyButtonloading = true;
                this.$store.dispatch('basket/updateItem', {
                    productId: this.product.id,
                    quantity: 1,
                })
                    .then(() => {
                        this.isBuyButtonloading = false;
                        this.$modal.show('product-added-to-basket-modal', {
                            product: this.product,
                        });
                    })
                    .catch((error) => {
                        this.isBuyButtonloading = false;
                        handleException(error);
                    });
            }
        },
        trackClick() {
            this.$bus.$emit('product-impression-click', {
                product: this.product,
                list: this.list,
                position: this.position,
                category: this.category || this.product.category,
            });
        },
    },

};
</script>

<style lang="scss" scoped>
.card {
    @apply overflow-hidden relative select-none rounded transition-fast cursor-pointer;
    &:hover {
        .card__new-banner {
            @apply opacity-0 transform -translate-y-full;
        }
    }
    &__image {
        object-fit: cover;
        object-position: center;
        @apply block h-full w-full transition-fast;
        &__hover {
            @apply opacity-0 absolute inset-0 transition-opacity duration-1000;
            object-fit: cover;
            object-position: center;
        }
    }
}
</style>
