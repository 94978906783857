<template>
    <modal
        :max-width="maxWidth"
        name="auth-modal"
        height="auto"
        scrollable
        adaptive
        @before-open="handleModalBeforeOpen"
    >
        <div class="py-8 px-10">

            <component
                :is="component"
                :email="email"
                @change-component="changeComponent"
            />

        </div>
    </modal>
</template>

<script>
import AuthModalSignIn from '~/components/AuthModalSignIn';
import AuthModalRegister from '~/components/AuthModalRegister';
import AuthModalForgotPassword from '~/components/AuthModalForgotPassword';

export default {

    components: {
        AuthModalSignIn,
        AuthModalRegister,
        AuthModalForgotPassword,
    },

    data() {
        return {
            component: '',
            email: '',
        };
    },

    computed: {
        maxWidth() {
            return this.component === 'AuthModalRegister' ? 1500 : 500;
        },
    },

    methods: {
        changeComponent({ component, email = '' }) {
            this.component = component;
            this.email = email;
        },
        handleModalBeforeOpen() {
            this.email = '';
            this.component = 'AuthModalSignIn';
        },
    },

};
</script>

<style lang="scss" scoped>
</style>
