<template>
    <div>
        <p
            v-if="available"
            class="flex"
        >
            <CheckIcon class="h-10 text-green-light mr-8 mt-1" />

            <span>
                Wahoo! We deliver to
                <strong>{{ postcode }}</strong>
                <span v-if="price"><br>(delivery fee: £{{ price }})</span>
            </span>
        </p>

        <div v-else>
            <p class="text-red-light">
                Our Click+ range isn't currently available to purchase online for delivery to
                <strong>{{ postcode }}</strong>. For a quote on delivery, please email
                <a href="mailto:sales@bubblegumballoons.co.uk">sales@bubblegumballoons.co.uk</a> and the team will be
                happy to help.
            </p>

            <div
                v-if="crossSells?.length > 0"
                class="mt-5"
            >
                <p>Why not check out some of these alternative products?</p>
                <div class="grid grid-cols-2 gap-4">
                    <ProductCard
                        v-for="crossSell in crossSells"
                        :key="crossSell.id"
                        :product="crossSell"
                    />
                </div>
            </div>
        </div>

        <p
            class="text-sm font-semibold text-primary mt-5 no-underline transition-fast cursor-pointer"
            @click="resetDeliveryAddress"
        >
            Change delivery address
        </p>
    </div>
</template>

<script>
import CheckIcon from '~/static/fonts/LineIcons/checkmark-circle.svg';
import ProductCard from '@/components/ProductCard.vue';

export default {

    components: {
        CheckIcon,
        ProductCard,
    },

    props: {
        available: {
            type: Boolean,
            required: false,
            default: false,
        },
        postcode: {
            type: String,
            required: false,
            default: '',
        },
        price: {
            type: Number,
            required: false,
            default: 0,
        },
        crossSells: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        resetDeliveryAddress() {
            this.$store.dispatch('checkout/resetProductsChecked');
        },
    },

};
</script>

<style scoped>
    p {
        @apply mb-5;
    }
</style>
