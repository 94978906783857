import axios from 'axios';
import handleException from '../../plugins/error-handler.client';

export default {

    setNavItems({ commit }, navItems) {
        commit('setNavItems', navItems);
    },

    hideReferralPopup({ commit }) {
        localStorage.setItem('dismissed-referral-popup', new Date().toISOString());
        commit('INCREASE_PAGE_COUNT');
    },

    toggleSidebar({ commit }) {
        commit('TOGGLE_SIDEBAR');
    },

    setShowSidebar({ commit }, showSidebar) {
        commit('SET_SHOW_SIDEBAR', showSidebar);
    },

    loadBlogPosts({ commit, getters }) {
        if (getters.blogPosts.length === 0) {
            this.$axios
                .$get('https://bubblegumballoons.com/blog/wp-json/wp/v2/posts?_embed&per_page=3')
                .then((response) => commit('SET_BLOG_POSTS', response));
        }
    },

    loadHomePage({ commit }) {
        const client = axios.create();

        client
            .get('https://cms.bubblegumballoons.com/api/pages/1', {
                params: {
                    populate: [
                        'grids.items.components',
                        'grids.items.components.image',
                        'grids.items.components.button',
                        'grids.items.products',
                        'grids.items.background_image',
                    ],
                },
            })
            .then((response) => {
                commit('SET_HOME_PAGE', response.data.data.attributes);
            });
    },

    setMegaMenuOpen({ commit }, open) {
        commit('SET_MEGA_MENU_OPEN', open);
    },

    async getDirectusSettings({ commit }) {
        try {
            const client = axios.create();

            const response = await client.get('https://content.bubblegumballoons.com/items/settings');

            commit('SET_DIRECTUS_SETTINGS', response.data?.data || {});
        } catch (e) {
            // Ignore
        }
    },

    async getHomePage({ getters, dispatch }) {
        return dispatch('getPage', getters.directusSettings?.home_page);
    },

    async getPage({ commit }, slug) {
        try {
            const client = axios.create();

            const response = await client
                .get('https://content.bubblegumballoons.com/items/pages', {
                    params: {
                        filter: {
                            _or: [
                                {
                                    path: {
                                        _eq: slug,
                                    },
                                },
                                {
                                    path: {
                                        _eq: `/${slug}`,
                                    },
                                },
                                {
                                    id: {
                                        _eq: `${slug}`,
                                    },
                                },
                            ],
                        },
                        fields: [
                            '*',
                            'grids.*',
                            'grids.items.*',
                            'grids.items.components.*',

                            // Images
                            'grids.items.components.item:images.*',
                            'grids.items.components.item:images.sizes.*',
                            'grids.items.components.item:images.sizes.directus_files_id.*',

                            // Markdown
                            'grids.items.components.item:markdown.*',

                            // Buttons
                            'grids.items.components.item:buttons.*',

                            // Carousel
                            'grids.items.components.item:carousels.*',
                            'grids.items.components.item:carousels.slides.*',
                            'grids.items.components.item:carousels.slides.components.*',
                            'grids.items.components.item:carousels.slides.background.*',
                            'grids.items.components.item:carousels.slides.background.sizes.*',
                            'grids.items.components.item:carousels.slides.background.sizes.directus_files_id.*',
                            'grids.items.components.item:carousels.slides.components.item:images.*',
                            'grids.items.components.item:carousels.slides.components.item:images.sizes.*',
                            'grids.items.components.item:carousels.slides.components.item:images.sizes.directus_files_id.*',
                            'grids.items.components.item:carousels.slides.components.item:markdown.*',
                            'grids.items.components.item:carousels.slides.components.item:buttons.*',
                            'grids.items.components.item:carousels.slides.components.item:links.*',
                            'grids.items.components.item:carousels.slides.components.item:videos.*',

                            // Product grids
                            'grids.items.components.item:product_grids.*',

                            // Links
                            'grids.items.components.item:links.*',

                            // Videos
                            'grids.items.components.item:videos.*',

                            // Help Categories
                            'grids.items.components.item:help_categories.*',
                            'grids.items.components.item:help_categories.articles.articles_id.*',
                        ],
                        limit: 1,
                    },
                });

            return response.data?.data[0] || {};
        } catch (e) {
            // Ignore
        }
    },

    async getMenu({ commit, getters }) {
        const id = getters.directusSettings?.menu;

        try {
            const client = axios.create();

            const response = await client
                .get(`https://content.bubblegumballoons.com/items/menus/${id}`, {
                    params: {
                        fields: [
                            '*',
                            'items.*',
                            'items.menu_items_id.*',
                            'items.menu_items_id.grids.grids_id.*',
                            'items.menu_items_id.grids.grids_id.items.*',
                            'items.menu_items_id.grids.grids_id.items.components.*',

                            // Images
                            'items.menu_items_id.grids.grids_id.items.components.item:images.*',
                            'items.menu_items_id.grids.grids_id.items.components.item:images.sizes.*',
                            'items.menu_items_id.grids.grids_id.items.components.item:images.sizes.directus_files_id.*',

                            // Markdown
                            'items.menu_items_id.grids.grids_id.items.components.item:markdown.*',

                            // Buttons
                            'items.menu_items_id.grids.grids_id.items.components.item:buttons.*',

                            // Links
                            'items.menu_items_id.grids.grids_id.items.components.item:links.*',
                        ],
                    },
                });

            commit('SET_MENU', response.data?.data || {});
        } catch (e) {
            // Ignore
        }
    },

};
