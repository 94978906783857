export default {

    /**
     * Logs the user in with the auth module and then adds them to our store.
     */
    async login({ dispatch, rootGetters }, data) {
        await this.$auth.loginWith('local', {
            data: {
                ...data,
                basket_id: rootGetters['basket/id'],
            },
        });
        await dispatch('setUser', this.$auth.user);
        this.$braze.changeUser(this.$auth.user.code);
    },

    /**
     * Sets the user in the store and maps their address to our data structure.
     */
    setUser({ commit }, user) {
        commit('setUser', {
            ...user,
            addresses: user.addresses.map((address) => ({
                id: address.id || '',
                name: address.name || '',
                firstLine: address.line_1 || '',
                secondLine: address.line_2 || '',
                thirdLine: address.line_3 || '',
                company: address.company || '',
                city: address.city || '',
                county: address.region || '',
                country: {
                    id: address.country.id,
                    name: address.country.name,
                },
                postcode: address.post_code || '',
                isActive: address.is_active || '',
            })),
        });
    },

    /**
     * Logs the user out of the auth module, resets the basket, checkout and user store modules.
     */
    async logout({ commit, dispatch }) {
        await this.$auth.logout();
        await dispatch('basket/reset', null, { root: true });
        await dispatch('checkout/reset', null, { root: true });
        await commit('reset');
    },

    setFirstName({ commit }, firstName) {
        commit('setFirstName', firstName);
    },

    setLastName({ commit }, lastName) {
        commit('setLastName', lastName);
    },

    setEmail({ commit }, email) {
        commit('setEmail', email);
    },

    setPhone({ commit }, phone) {
        commit('setPhone', phone);
    },

    setGiftMessage({ commit }, giftMessage) {
        commit('setGiftMessage', giftMessage);
    },

    setAddresses({ commit }, addresses) {
        commit('setAddresses', addresses);
    },

};
