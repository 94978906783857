<template>
    <nav class="py-5 text-sm font-semibold bg-pink-100">

        <div class="container mx-auto">
            <ul class="flex flex-wrap justify-center lg:text-left -mb-3 lg:mb-0 space-x-5 px-4 footer-nav">
                <template v-for="(item, index) in items">
                    <li
                        :key="`item-${index}`"
                        class="mb-3 lg:m-0"
                    >
                        <a
                            v-if="item.component === 'a'"
                            :href="item.to"
                        >
                            {{ item.title }}
                        </a>
                        <nuxt-link
                            v-else
                            :to="item.to"
                        >
                            {{ item.title }}
                        </nuxt-link>
                    </li>
                    <li
                        :key="`separator-${index}`"
                        class="select-none hidden lg:inline-block separator"
                    >
                        &bullet;
                    </li>
                </template>
            </ul>
        </div>

    </nav>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    title: 'How It Works',
                    to: '/how-it-works',
                },
                {
                    title: 'Blog',
                    to: '/blog',
                    component: 'a',
                },
                {
                    title: 'Biodegradable',
                    to: '/pages/sustainability-page/',
                },
                {
                    title: 'FAQ',
                    to: '/help',
                },
                {
                    title: 'Contact Us',
                    to: '/contact-us',
                },
                {
                    title: 'Delivery',
                    to: '/delivery',
                },
                {
                    title: 'Collection',
                    to: '/balloon-collections',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
a {
    @apply no-underline;
    &:hover {
        @apply text-primary;
    }
}

.separator:last-child {
    display: none;
}
</style>
