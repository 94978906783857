import { render, staticRenderFns } from "./SlideMenuItem.vue?vue&type=template&id=486290bb&scoped=true&"
import script from "./SlideMenuItem.vue?vue&type=script&lang=js&"
export * from "./SlideMenuItem.vue?vue&type=script&lang=js&"
import style0 from "./SlideMenuItem.vue?vue&type=style&index=0&id=486290bb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486290bb",
  null
  
)

export default component.exports