export default () => ({
    productVariations: {},
    customisationImage: null,
    defaultImage: null,
    selectedVariations: [],
    text: '',
    textLine2: '',
    imageScale: 1,
    isBuyButtonLoading: false,
    totalPrice: 0,
    quantity: null,
    customerImageUrl: null,
    customerImagePreview: null,
    customerImageWidth: 100,
    customerImageHeight: 100,
    canvasPreview: null,
    showStockNotification: false,
    fontId: null,
    fonts: [],
    showProductCustomisationImage: false,
    isVariationImage: false,
    activeVariationImage: null,
});
