<template>
    <div class="bg-white py-4 px-8">

        <div class="flex justify-between items-center">

            <HeaderMainMobileMenu />

            <nuxt-link to="/">
                <img
                    v-lazy="'/images/logo-mobile.svg'"
                    class="h-8"
                    width="2010"
                    height="625"
                >
            </nuxt-link>

            <div class="flex items-center justify-end space-x-4">
                <HeaderMainMobileBasket />
                <HeaderMainMobileSearch />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderMainMobileSearch from '~/components/HeaderMainMobileSearch';
import HeaderMainMobileMenu from '~/components/HeaderMainMobileMenu';
import HeaderMainMobileBasket from '~/components/HeaderMainMobileBasket';
import HeaderMainMobileAccount from '~/components/HeaderMainMobileAccount';

export default {

    components: {
        HeaderMainMobileSearch,
        HeaderMainMobileMenu,
        HeaderMainMobileBasket,
        HeaderMainMobileAccount,
    },

};
</script>

<style lang="scss" scoped>
</style>
