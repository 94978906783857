<template>
    <div class="text-center">
        <nuxt-link
            v-if="image"
            :to="link"
            @click.native="$emit('click')"
        >
            <img
                v-lazy="image"
                class="image w-full"
            >
        </nuxt-link>

        <div class="mb-3 mt-1">
            <nuxt-link
                :to="link"
                class="no-underline hover:text-primary transition-fast block mb-1 leading-tight"
                @click.native="$emit('click')"
            >
                <div class="name text-sm">
                    <div
                        v-if="shopAll"
                        class="underline"
                    >
                        SHOP ALL
                    </div>
                    {{ name }}
                </div>
            </nuxt-link>
            <Price
                v-if="price"
                :price="price"
                class="text-sm"
            />
        </div>
    </div>
</template>

<script>
import Price from './Price';

export default {
    components: {
        Price,
    },

    props: {
        link: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        price: {
            type: String,
            default: null,
        },
        image: {
            type: String,
            default: null,
        },
        shopAll: {
            type: Boolean,
            default: false,
        },
    },

};
</script>

<style lang="scss" scoped>
.image {
    @apply rounded;
}
.name {
    @apply inline-block font-semibold whitespace-normal;
}
</style>
