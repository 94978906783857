<template>
    <div>

        <FooterSocial />
        <FooterNav />
        <FooterLegal />

    </div>
</template>

<script>
import FooterNav from '~/components/FooterNav';
import FooterSocial from '~/components/FooterSocial';
import FooterLegal from '~/components/FooterLegal';

export default {

    components: {
        FooterNav,
        FooterSocial,
        FooterLegal,
    },

};
</script>

<style scoped>
</style>
