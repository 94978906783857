<template>
    <div>
        <hr>
        <h2 class="mb-3">
            Check delivery availability
        </h2>
        <p class="mb-3">
            {{ product ? 'This product is' : 'These products are' }} only available for delivery in some locations. Use our delivery checker below to check whether we can deliver to your address.
        </p>

        <IdealPostcodesAutocomplete @selected="checkAddress($event)" />

        <p
            v-if="product"
            class="text-sm font-semibold text-primary mt-10 no-underline transition-fast opacity-75 cursor-pointer"
            @click="openDeliveryModal"
        >
            View list of available delivery areas
        </p>
        <hr>
    </div>
</template>
<script>
export default {
    props: {
        product: {
            type: Object,
            required: false,
            default: () => {},
        },
        shippingClass: {
            type: Number,
            required: true,
        },
    },

    methods: {
        checkAddress(address) {
            const productId = this.shippingClass;
            const shippingAddress = {
                firstLine: address.line_1 || '',
                secondLine: address.line_2 || '',
                city: address.post_town || '',
                county: address.county || '',
                country: address.country || '',
                postcode: address.postcode || '',
            };

            this.$store.dispatch('checkout/checkDeliveryAddressHasShippingRates', {
                shippingAddress,
                productId,
            });

            if (!this.$store.getters['basket/id']) {
                this.$store.dispatch('checkout/createOrder');
            } else {
                this.$store.dispatch('checkout/updateOrder');
            }
        },
        openDeliveryModal() {
            this.$modal.show('product-modals-modal', {
                title: 'Delivery',
                html: this.product.delivery_modal_html,
            });
        },
    },
};
</script>
