import axios from 'axios';
import handleException from '../../plugins/error-handler.client';

export default {

    async fetchCategories({ commit }) {
        try {
            const client = axios.create();

            const response = await client
                .get('https://content.bubblegumballoons.com/items/help_categories', {
                    params: {
                        filter: {
                            display_on_help_page: {
                                _eq: true,
                            },
                        },
                        fields: [
                            '*',
                            'image.*',
                            'articles.articles_id.*',
                        ],
                    },
                });

            commit('SET_CATEGORIES', response.data.data);
        } catch (error) {
            handleException(error);
        }
    },

    setActiveCategory({ commit }, categorySlug) {
        commit('SET_ACTIVE_CATEGORY', categorySlug);
    },

    setActiveArticle({ commit }, articleSlug) {
        commit('SET_ACTIVE_ARTICLE', articleSlug);
    },
};
