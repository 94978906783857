<template>
    <transition name="fade">
        <div
            v-show="isShown"
            class="button hidden xl:flex"
            @click="scrollToTop"
        >
            <ChevronUpIcon class="h-6" />
        </div>
    </transition>
</template>

<script>
import ChevronUpIcon from '~/static/fonts/LineIcons/chevron-up.svg';

export default {

    components: {
        ChevronUpIcon,
    },

    data() {
        return {
            isShown: false,
        };
    },

    mounted() {
        window.addEventListener('scroll', () => {
            this.isShown = window.scrollY > 0;
        });
    },

    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },

};
</script>

<style lang="scss" scoped>
.button {
    @apply rounded-full bg-primary shadow-lg text-white h-16 w-16 fixed justify-center items-center cursor-pointer m-8 z-40 transition-fast;
    &:hover {
        @apply opacity-75;
    }
    bottom: 0;
    left: 50%;
    margin-left: -2rem;
}
.fade-enter-active,
.fade-leave-active {
    @apply transition-slow;
}
.fade-enter,
.fade-leave-to {
    @apply opacity-0;
    transform: translateY(10px);
}
</style>
