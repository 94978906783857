<template>
    <div :class="`text-${section.align}`">
        <HeaderNavLink
            v-if="section.image.data"
            :url="section.url"
        >
            <img
                v-lazy="imageSrc"
                :alt="section.image.data.attributes.alternativeText"
                class="rounded mb-2"
            >
        </HeaderNavLink>
        <h4 :class="`text-${section.title_size || 'base'} font-${section.title_weight || 'bold'}`">
            <HeaderNavLink :url="section.url">
                {{ section.title }}
            </HeaderNavLink>
        </h4>
        <Markdown
            v-if="section.content"
            :markdown="section.content"
        />
        <HeaderNavBlogPosts
            v-if="section.show_latest_blog_posts"
            class="my-4"
        />
        <ul
            v-if="section.link_collection && section.link_collection.data && section.link_collection.data.attributes.links.length > 0"
            class="gap-y-4 mt-2"
            :class="`sm:columns-${section.link_collection.data.attributes.columns}`"
        >
            <li
                v-for="link in section.link_collection.data.attributes.links"
                :key="`link-${link.id}`"
                class="mb-2 hover:text-primary"
            >
                <HeaderNavLink :url="link.url">
                    {{ link.title }}
                </HeaderNavLink>
            </li>
        </ul>
    </div>
</template>

<script>
import HeaderNavLink from './HeaderNavLink';
import Markdown from './Markdown';
import HeaderNavBlogPosts from './HeaderNavBlogPosts';

export default {
    components: {
        HeaderNavLink,
        Markdown,
        HeaderNavBlogPosts,
    },
    props: {
        section: {
            type: Object,
            required: true,
        },
    },
    computed: {
        imageSrc() {
            if (this.section.image.data && this.section.image.data.attributes.url) {
                if (this.section.image.data.attributes.url.includes('https')) {
                    return this.section.image.data.attributes.url;
                }

                return `/cms/${this.section.image.data.attributes.url}`;
            }

            return '';
        },
    },
};
</script>

<style scoped>

</style>
