<template>
    <transition name="fade">

        <div
            v-show="isOpen"
            class="relative z-40"
        >
            <div
                v-if="hasResults"
                :class="{ 'dropdown__results--centered' : centered }"
                class="dropdown__results max-w-full md:max-w-screen-md"
            >
                <div v-show="hasCategoryResults">
                    <div class="text-xs tracking-wide leading-none font-bold text-grey-dark select-none px-5 py-3">
                        CATEGORIES
                    </div>
                    <div class="search-results-grid">
                        <HeaderMainSearchResultsDropdownResult
                            v-for="(category, index) in results.categories.items"
                            :key="index"
                            :link="`/categories/${category.slug}`"
                            :name="category.name"
                            :image="category.image.sizes.small"
                            :shop-all="true"
                            @click="$emit('close')"
                        />
                    </div>
                    <div
                        v-show="false"
                        class="p-5 mb-3 text-xs"
                    >
                        <nuxt-link
                            :to="`/search?query=${query}`"
                            class="no-underline hover:text-primary transition-fast"
                        >
                            and {{ remainingCategoryResults }} more categor{{ remainingCategoryResults > 1 ? 'ies' : 'y' }}...
                        </nuxt-link>
                    </div>
                </div>

                <div v-show="hasProductResults">
                    <div class="text-xs tracking-wide leading-none font-bold text-grey-dark select-none px-5 py-3">
                        PRODUCTS
                    </div>
                    <div class="search-results-grid">
                        <HeaderMainSearchResultsDropdownResult
                            v-for="(product, index) in results.products.items"
                            :key="index"
                            :link="`/products/${product.id}/${product.slug}`"
                            :name="product.name"
                            :price="product.price"
                            :image="product.image.sizes.small"
                            @click="$emit('close')"
                        />
                    </div>
                    <div
                        v-show="false"
                        class="mx-5 mb-3 text-xs"
                    >
                        <nuxt-link
                            :to="`/search?query=${query}`"
                            class="no-underline hover:text-primary transition-fast"
                        >
                            and {{ remainingProductResults }} more product{{ remainingProductResults > 1 ? 's' : '' }}...
                        </nuxt-link>
                    </div>
                    <nuxt-link
                        :to="`/search?query=${query}`"
                        class="text-white font-semibold flex justify-center w-1/2 m-auto cursor-pointer items-center text-decoration-none bg-primary focus:ring whitespace-nowrap"
                    >
                        View all
                    </nuxt-link>
                </div>

                <div v-show="hasPageResults">
                    <div class="text-xs tracking-wide leading-none font-bold text-grey-dark select-none px-5 py-3">
                        PAGES
                    </div>
                    <div class="search-results-grid">
                        <HeaderMainSearchResultsDropdownResult
                            v-for="(page, index) in results.pages.items"
                            :key="index"
                            :link="`/${page.slug}`"
                            :name="page.name"
                            :image="page.image"
                            @click="$emit('close')"
                        />
                    </div>
                    <div
                        v-show="false"
                        class="p-5 mb-3 text-xs"
                    >
                        <nuxt-link
                            :to="`/search?query=${query}`"
                            class="no-underline hover:text-primary transition-fast"
                        >
                            and {{ remainingPageResults }} more page{{ remainingPageResults > 1 ? 's' : '' }}...
                        </nuxt-link>
                    </div>
                </div>

            </div>

            <div
                v-else
                class="dropdown__results dropdown__results--centered"
            >
                <div class="font-bold select-none px-5 py-3">
                    NO RESULTS
                </div>
            </div>

            <div class="caret" />

        </div>
    </transition>
</template>

<script>
import HeaderMainSearchResultsDropdownResult from '~/components/HeaderMainSearchResultsDropdownResult';

export default {

    components: {
        HeaderMainSearchResultsDropdownResult,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        query: {
            type: String,
            required: true,
        },
        results: {
            type: Object,
            required: true,
        },
        centered: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasResults() {
            return this.hasProductResults || this.hasCategoryResults || this.hasPageResults;
        },
        hasProductResults() {
            return this.results.products.total > 0;
        },
        remainingProductResults() {
            return this.results.products.total - 5;
        },
        hasCategoryResults() {
            return this.results.categories.total > 0;
        },
        remainingCategoryResults() {
            return this.results.categories.total - 5;
        },
        hasPageResults() {
            return this.results.pages.total > 0;
        },
        remainingPageResults() {
            return this.results.pages.total - 5;
        },
    },

};
</script>

<style lang="scss" scoped>
a {
    @apply no-underline;
    &:hover {
        @apply opacity-75 shadow-md;
    }
}
.dropdown__results {
    width: 400px;
    border: 2px solid;
    top: 100%;
    right: 0;
    @apply border-primary bg-white absolute whitespace-nowrap mt-3 rounded text-left shadow-md py-3;
    &--centered {
        right: 50%;
        transform: translateX(50%);
        @apply text-center;
    }
    @media (max-width: config("theme.screens.md")) {
        @apply overflow-y-auto;
        max-height: 75vh;
    }
}
.caret {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 6px solid black;
    bottom: -12px;
    right: 50%;
    transform: translateX(50%);
    @apply border-primary;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.fade-enter-active,
.fade-leave-active {
    @apply transition-slow;
}
.fade-enter,
.fade-leave-to {
    @apply opacity-0;
    transform: translateY(10px);
}
.search-results-grid {
    @apply grid grid-cols-2 gap-3 px-5;
}
</style>

