<template>
    <div>
        <h5 class="font-heading text-2xl mb-2 font-light">
            Be the first to know
        </h5>

        <p class="text-xs mb-2">
            Get all the latest information on Events,
            Sales and Offers. Sign up for our newsletter today.
        </p>

        <NewsletterSubscribe />
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import Input from '@/components/Input';
import Button from '@/components/Button';
import handleException from '../plugins/error-handler.client';
import NewsletterSubscribe from '@/components/forms/NewsletterSubscribe.vue';

export default {
    components: {
        NewsletterSubscribe,
    },
};
</script>
