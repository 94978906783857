<script>
import Button from '~/components/pages/components/Button.vue';
import Carousel from '~/components/pages/components/Carousel.vue';
import Image from '~/components/pages/components/Image.vue';
import Markdown from '~/components/pages/components/Markdown.vue';
import Links from '~/components/pages/components/Links.vue';
import Products from '~/components/pages/components/Products.vue';
import HelpCategory from '~/components/pages/components/HelpCategory.vue';
import Video from '~/components/pages/components/Video.vue';

const components = {
    buttons: Button,
    carousels: Carousel,
    images: Image,
    markdown: Markdown,
    links: Links,
    product_grids: Products,
    help_categories: HelpCategory,
    videos: Video,
};

export default {
    props: {
        component: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        getComponent() {
            return components[this.component.collection];
        },
    },
};
</script>

<template>
    <component
        :is="getComponent"
        :component="component.item"
    />
</template>

<style scoped lang="scss">

</style>
