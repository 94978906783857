import MobileDetect from 'mobile-detect';

export default (context) => {
    let userAgent;

    if (typeof context.req !== 'undefined') {
        userAgent = context.req.headers['user-agent'];
    } else if (typeof window !== 'undefined') {
        userAgent = window.navigator.userAgent;
    }

    const mobileDetect = new MobileDetect(userAgent);

    context.store.dispatch('settings/setIsMobile', mobileDetect.mobile() !== null);
};

