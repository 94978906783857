export default {

    SET_CATEGORIES(state, categories) {
        state.categories = categories;
    },

    SET_ACTIVE_CATEGORY(state, categorySlug) {
        state.activeCategorySlug = categorySlug;
    },

    SET_ACTIVE_ARTICLE(state, articleSlug) {
        state.activeArticleSlug = articleSlug;
    },
};
