<script>
import SlideComponent from '@/components/pages/components/SlideComponent.vue';
import BackgroundImage from '@/components/pages/components/Image.vue';

export default {
    components: { BackgroundImage, SlideComponent },
    props: {
        slide: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        slideUrl() {
            return this.slide.url?.replace('https://bubblegumballoons.com', '');
        },
    },
};
</script>

<template>
    <div class="relative">
        <BackgroundImage
            v-if="slide.background"
            :component="slide.background"
            class="w-full h-full absolute inset-0"
            fit="cover"
            :width="1920"
        />

        <component
            :is="slide.url ? 'nuxt-link' : 'div'"
            :to="slideUrl"
            :class="[`style-${slide.style} px-2 relative block`, slide.contain ? 'container mx-auto px-4 md:px-0' : '']"
        >
            <SlideComponent
                v-for="(component, index) in slide.components"
                :key="`component-${index}`"
                :component="component"
            />
        </component>
    </div>

</template>

<style scoped lang="scss">

</style>
