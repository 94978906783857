export default {

    categories: (state) => state.categories,

    activeArticleSlug(state) {
        return state.activeArticleSlug;
    },

    activeCategorySlug(getters) {
        return getters.activeCategorySlug;
    },

    activeCategory: (state, getters) => {
        if (state.activeCategorySlug) {
            return getters.categories.find((category) => category.slug === state.activeCategorySlug);
        }

        return getters.categories[0] ?? null;
    },

    activeArticle: (state, getters) => {
        if (getters.activeCategory?.articles) {
            return getters.activeCategory.articles.map((article) => article.articles_id).find((article) => article.slug === state.activeArticleSlug)
                ?? getters.activeCategory.articles[0]?.articles_id ?? null;
        }

        return null;
    },

};
