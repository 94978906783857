export default () => ({

    settings: [],
    nextDeliveryDate: null,
    nextDeliveryCutOff: null,
    nextDeliveryCutOffInterval: null,
    nextDeliveryCutOffCountdown: null,
    dateFormat: 'dddd D MMMM YYYY',
    noticeCarouselIndex: 0,
    collectionHolidays: [],
    deliveryHolidays: [],
    availableRates: [],
    isMobile: false,

});
