<template>
    <button
        class="flex items-center font-semibold text-black"
        @click="goToCheckout"
    >
        <div class="flex relative mr-2">
            <CartIcon class="cart-icon" />
            <span class="items-count-badge">{{ basketItemsCount }}</span>
        </div>
    </button>
</template>

<script>
import CartIcon from '~/static/fonts/LineIcons/shopping-basket.svg';

export default {

    components: {
        CartIcon,
    },

    computed: {
        basketItemsCount() {
            return this.$store.getters['basket/itemCount'];
        },
        basketTotalPrice() {
            return this.$store.getters['basket/totalPrice'];
        },
    },

    methods: {
        goToCheckout() {
            this.basketItemsCount && this.$router.push('/checkout');
        },
    },

};
</script>

<style lang="scss" scoped>
.cart-icon {
    height: 22px;
}
.items-count-badge {
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    @apply bg-primary text-white text-xs absolute flex items-center justify-center rounded-full select-none;
}
</style>
