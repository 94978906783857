import Vue from 'vue';

export default ({ $gtm }, inject) => {
    const bus = new Vue();

    bus.$on('product-impression', ({ product, list, position, category }) => {
        $gtm.push({
            event: 'eec.impressionView',
            ecommerce: {
                impressions: [
                    {
                        id: product.id,
                        name: product.name,
                        category: category || product.category,
                        list,
                        position,
                    },
                ],
            },
        });

        $gtm.push({
            event: 'view_item_list',
            ecommerce: {
                items: [
                    {
                        item_id: product.id,
                        item_name: product.name,
                        item_category: category || product.category,
                        item_list_id: list,
                        item_list_name: list,
                        index: position,
                    },
                ],
            },
        });
    });

    bus.$on('product-impression-click', ({ product, list, position, category }) => {
        $gtm.push({
            event: 'eec.impressionClick',
            ecommerce: {
                click: {
                    actionField: {
                        list,
                    },
                    products: [
                        {
                            id: product.id,
                            name: product.name,
                            category: category || product.category,
                            position,
                        },
                    ],
                },
            },
        });

        $gtm.push({
            event: 'select_item',
            ecommerce: {
                items: [
                    {
                        item_id: product.id,
                        item_name: product.name,
                        item_category: category || product.category,
                        item_list_id: list,
                        item_list_name: list,
                        index: position,
                    },
                ],
            },
        });
    });

    bus.$on('product-detail', ({ product }) => {
        $gtm.push({
            event: 'eec.detail',
            ecommerce: {
                detail: {
                    products: [
                        {
                            id: product.id,
                            name: product.name,
                            category: product.category,
                        },
                    ],
                },
            },
        });

        $gtm.push({
            event: 'view_item',
            ecommerce: {
                items: [
                    {
                        item_id: product.id,
                        item_name: product.name,
                        category: product.category,
                    },
                ],
            },
        });
    });

    bus.$on('basket-add', ({ product }) => {
        $gtm.push({
            event: 'eec.add',
            ecommerce: {
                add: {
                    products: [
                        {
                            id: product.id,
                            name: product.name,
                            category: product.category,
                            price: parseFloat(product.from_price),
                            quantity: 1,
                        },
                    ],
                },
            },
        });

        $gtm.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: 'GBP',
                value: parseFloat(product.from_price),
                items: [
                    {
                        item_id: product.id,
                        item_name: product.name,
                        category: product.category,
                        price: parseFloat(product.from_price),
                        quantity: 1,
                    },
                ],
            },
        });
    });

    bus.$on('checkout', ({ products, step, stepName }) => {
        $gtm.push({
            event: 'eec.checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step,
                    },
                    products: products || [],
                },
            },
        });

        if (stepName) {
            $gtm.push({
                event: 'eec.checkout_option',
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            step,
                            option: stepName,
                        },
                    },
                },
            });
        }

        $gtm.push({
            event: 'begin_checkout',
            ecommerce: {
                currency: 'GBP',
                value: (products || []).reduce((total, product) => total + product.price * product.quantity, 0),
                items: (products || []).map((product) => ({
                    item_id: product.id,
                    item_name: product.name,
                    category: product.category,
                    price: parseFloat(product.price),
                    quantity: product.quantity,
                })),
            },
        });
    });

    bus.$on('purchase', (order) => {
        $gtm.push({
            event: 'eec.purchase',
            ecommerce: {
                currencyCode: 'GBP',
                purchase: {
                    actionField: {
                        id: order.id,
                        revenue: parseFloat(order.revenue),
                        shipping: parseFloat(order.shipping),
                        coupon: order.coupon,
                    },
                    products: order.products,
                },
            },
        });

        if (typeof order.products === 'undefined') {
            order.products = [];
        }

        $gtm.push({
            event: 'purchase',
            ecommerce: {
                currency: 'GBP',
                transaction_id: order.id,
                value: parseFloat(order.revenue),
                coupon: order.coupon,
                shipping: order.shipping,
                items: order.products.map((product) => ({
                    item_id: product.id,
                    item_name: product.name,
                    category: product.category,
                    price: parseFloat(product.price),
                    quantity: product.quantity,
                })),
            },
        });
    });

    inject('bus', bus);
};
