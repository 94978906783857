<template>
    <div
        v-on-clickaway="closeDropdown"
        class="relative z-40"
    >

        <div
            :class="{ 'text-primary': isDropdownOpen }"
            class="flex items-center hover:text-primary cursor-pointer font-semibold transition-fast"
            @click="openDropdown"
        >
            <div class="flex relative">
                <CartIcon class="cart-icon" />
                <span class="items-count-badge">{{ basketItemsCount }}</span>
            </div>
            <span class="text-xs hidden">£{{ basketTotalPrice }}</span>
        </div>

        <HeaderMainBasketDropdown
            :is-open="isDropdownOpen"
            @close="closeDropdown"
        />

    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import CartIcon from '~/static/fonts/LineIcons/shopping-basket.svg';
import HeaderMainBasketDropdown from '~/components/HeaderMainBasketDropdown';

export default {

    components: {
        CartIcon,
        HeaderMainBasketDropdown,
    },

    mixins: [
        clickaway,
    ],

    data() {
        return {
            isDropdownOpen: false,
        };
    },

    computed: {
        basketItemsCount() {
            return this.$store.getters['basket/itemCount'];
        },
        basketTotalPrice() {
            return this.$store.getters['basket/totalPrice'];
        },
    },

    watch: {
        $route() {
            this.closeDropdown();
        },
    },

    methods: {
        openDropdown() {
            this.isDropdownOpen = true;
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        },
    },

};
</script>

<style scoped>
.cart-icon {
    height: 22px;
}
.items-count-badge {
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    @apply bg-primary text-white text-xs absolute flex items-center justify-center rounded-full select-none;
}
</style>
