<template>
    <div>
        <SadEmojiIcon class="h-32 mb-4" />

        <h1 class="mb-4 text-5xl">
            Oh no!
        </h1>
        <h1 class="mb-8 text-3xl">
            Something went wrong.
        </h1>
        <nuxt-link
            class="text-lg no-underline inline-block"
            to="/"
        >
            <Button text="Back to Home" />
        </nuxt-link>
    </div>
</template>

<script>
import Button from '@/components/Button';
import SadEmojiIcon from '~/static/fonts/LineIcons/emoji-sad.svg';

export default {

    components: {
        SadEmojiIcon,
        Button,
    },

};
</script>

<style lang="scss" scoped>
</style>
