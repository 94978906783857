import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import initialState from './initialState';

export default {
    // a common technique used to clone an object and prevent us referencing initialStore and mutating it
    state: () => JSON.parse(JSON.stringify(initialState)),
    actions,
    mutations,
    getters,
};
