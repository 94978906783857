export default {

    setNavItems(state, navItems) {
        state.navItems = navItems;
    },

    INCREASE_PAGE_COUNT(state) {
        state.pageCount += 1;
    },

    TOGGLE_SIDEBAR(state) {
        state.showSidebar = !state.showSidebar;
    },

    SET_SHOW_SIDEBAR(state, showSidebar) {
        state.showSidebar = showSidebar;
    },

    SET_BLOG_POSTS(state, blogPosts) {
        state.blogPosts = blogPosts;
    },

    SET_HOME_PAGE(state, homePage) {
        state.homePage = homePage;
    },

    SET_MEGA_MENU_OPEN(state, open) {
        state.megaMenuOpen = open;
    },

    SET_DIRECTUS_SETTINGS(state, directusSettings) {
        state.directusSettings = directusSettings;
    },

    SET_MENU(state, menu) {
        state.menu = menu;
    },
};
