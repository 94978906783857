<template>
    <span>{{ formatted }}</span>
</template>

<script>
export default {
    props: {
        price: {
            type: [Number, String],
            required: true,
        },
    },
    computed: {
        formatted() {
            let price = parseFloat(this.price);

            if (price === 0) {
                return 'FREE';
            }

            if (price % 1 !== 0) {
                price = price.toFixed(2);
            }

            return this.currency(price);
        },
    },
    methods: {
        currency(price) {
            return `£${price}`;
        },
    },
};
</script>

<style scoped>

</style>
