<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';

export default {
    name: 'Video',
    components: {
        vueVimeoPlayer,
    },
    props: {
        component: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            width: undefined,
            height: undefined,
        };
    },
    mounted() {
        if (this.$refs.video !== undefined) {
            this.width = this.$refs.video.clientWidth;
            this.height = this.$refs.video.clientHeight;
        }
    },
};
</script>

<template>
    <div
        ref="video"
        class="w-full h-full"
    >
        <client-only>
            <vimeo-player
                class="object-cover w-full h-full"
                :video-id="component.vimeo_id"
                :autoplay="true"
                :player-width="width"
                :player-height="height"
                :loop="true"
                :options="{ muted: true, unmute_button: true, progress_bar: true, play_button_position: 'center' }"
            />
        </client-only>
    </div>
</template>

<style scoped lang="scss">

</style>
