<template>
    <div>

        <h2 class="mb-5 font-normal">
            Login
        </h2>

        <TextInput
            v-model="email_"
            :invalid="$v.email_.$error"
            type="email"
            class="mb-5"
            label="Email"
            focused
            @enter="submit"
        >
            <template #errors>
                <div v-if="!$v.email_.required && $v.email_.$dirty">
                    Please enter your email address.
                </div>
                <div v-if="!$v.email_.email && $v.email_.$dirty">
                    Please enter a valid email address.
                </div>
            </template>
        </TextInput>

        <TextInput
            v-model="password"
            :focused="isPasswordFocused"
            :invalid="$v.password.$error || !!responseError"
            type="password"
            class="mb-5"
            label="Password"
            @enter="submit"
        >
            <template #errors>
                <div v-if="!$v.password.required && $v.password.$dirty">
                    Please enter your password.
                </div>
                <div v-if="responseError">
                    {{ responseError }}
                </div>
            </template>
        </TextInput>

        <div
            class="text inline-block mb-5"
            @click="changeComponent('AuthModalForgotPassword')"
        >
            Forgot your password?
        </div>

        <div class="flex flex-wrap items-center">
            <Button
                text="Sign In"
                @click="submit"
            />
            <div
                class="text w-full md:w-auto mt-4 md:mt-0 md:ml-6"
                @click="changeComponent('AuthModalRegister')"
            >
                Don't have an account? <span class="font-semibold">Register</span>.
            </div>
        </div>

    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import TextInput from '~/components/Input';
import Button from '~/components/Button';
import handleException from '../plugins/error-handler.client';

export default {

    components: {
        TextInput,
        Button,
    },

    props: {
        email: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            email_: '',
            password: '',
            isPasswordFocused: false,
            responseError: null,
        };
    },

    watch: {
        password() {
            this.responseError = null;
        },
    },

    created() {
        this.email_ = this.email;
        this.isPasswordFocused = !!this.email;
    },

    methods: {
        changeComponent(component) {
            this.$emit('change-component', {
                component,
                email: this.email_,
            });
        },
        async submit() {
            this.responseError = null;
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.$store.dispatch('user/login', {
                email: this.email_,
                password: this.password,
            })
                .then(() => {
                    this.$modal.hide('auth-modal');
                    this.$nuxt.$emit('refresh-page');
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        this.responseError = 'Incorrect password.';
                    } else {
                        handleException(error);
                    }
                });
        },
    },

    validations: {
        email_: {
            required,
            email,
        },
        password: {
            required,
        },
    },

};
</script>

<style lang="scss" scoped>
.text {
    @apply text-primary transition-fast cursor-pointer text-sm select-none;
    &:hover {
        @apply opacity-50;
    }
}
</style>
