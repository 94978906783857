export default {

    navItems: (state) => state.state.menu.items,
    showReferralPopup: (state) => state.pageCount > 2 && !localStorage.getItem('dismissed-referral-popup'),
    pageCount: (state) => state.pageCount,
    showSidebar: (state) => state.showSidebar,
    desktopNavItems: (state) => state.menu.items.filter((item) => item.display_on !== 'mobile'),
    mobileNavItems: (state) => state.menu.items.filter((item) => item.display_on !== 'desktop'),
    blogPosts: (state) => state.blogPosts,
    currentNavItem: (state) => state.currentNavItem,
    homePage: (state) => state.homePage,
    megaMenuOpen: (state) => state.megaMenuOpen,
    directusSettings: (state) => state.directusSettings,
    menu: (state) => state.menu,

};
