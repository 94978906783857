export default {

    id: (state) => state.id,
    hash: (state) => state.hash,
    code: (state) => state.code,
    items: (state) => state.items,
    itemCount: (state) => state.items.length,
    itemsPrice: (state) => state.itemsPrice,
    shippingPrice: (state) => state.shippingPrice,
    discountSavings: (state) => state.discountSavings,
    totalPrice: (state) => state.totalPrice,
    totalTax: (state) => state.totalTax,
    discountCode: (state) => state.discountCode,
    orderedFor: (state) => state.orderedFor,
    order: (state) => state.order,
    cheapestShippingRate: (state) => state.cheapestShippingRate,
    clickPlus: (state) => state.clickPlus,

};
