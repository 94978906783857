/**
 * Runs on inital page load to fetch the basket if they user has used the site before.
 */

export default async ({ app: { $axios, store, $cookies } }) => {
    const order = $cookies.get('basket');
    if (order) {
        try {
            const basket = await $axios.$get(`/basket/${order.id}`);
            await store.dispatch('basket/setBasket', basket);
            await store.dispatch('checkout/setOrder', basket);
        } catch (error) {
            $cookies.remove('basket');
        }
    }
};
