<script>

import { defineComponent } from 'vue';
import ProductAddressChecker from '@/components/ProductAddressChecker.vue';
import AddressCheckerResults from '@/components/AddressCheckerResults.vue';

export default defineComponent({
    components: {
        AddressCheckerResults,
        ProductAddressChecker,
    },
    props: {
        shippingClassId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        addressChecked() {
            return this.$store.getters['checkout/addressChecked'](this.shippingClassId);
        },
        deliveryPrice() {
            return this.$store.getters['checkout/getDeliveryPrice'](this.shippingClassId);
        },
        availableForDelivery() {
            return this.$store.getters['checkout/checkProductAvailable'](this.shippingClassId);
        },
        postcode() {
            return this.$store.getters['checkout/shippingAddress'].value.postcode;
        },
    },
});
</script>

<template>
    <div>
        <AddressCheckerResults
            v-if="addressChecked"
            class="mb-5"
            :available="availableForDelivery"
            :postcode="postcode"
            :price="deliveryPrice"
        />
        <ProductAddressChecker
            v-else
            :shipping-class="shippingClassId"
        />
    </div>
</template>

<style scoped lang="scss">

</style>
