<template>
    <div>
        <div class="flex md:items-center flex-col md:flex-row gap-4">

            <div class="wrapper select-none">

                <button
                    :disabled="isLoading"
                    class="button rounded-tl rounded-bl"
                    @click="updateQuantity(quantity_ - 1)"
                >
                    <MinusIcon class="icon" />
                </button>

                <div class="w-16 flex items-center justify-center">
                    <input
                        v-if="!isLoading"
                        :value="quantity_"
                        type="number"
                        min="0"
                        class="input"
                        @input="updateQuantity($event.target.value)"
                    >
                    <Spinner
                        v-else
                        size="20"
                    />
                </div>

                <button
                    :disabled="isLoading"
                    class="button rounded-tr rounded-br"
                    @click="updateQuantity(quantity_ + 1)"
                >
                    <PlusIcon class="icon" />
                </button>

            </div>

            <nuxt-link
                v-if="showText"
                class="currently-in-basket-text"
                to="/checkout"
            >
                currently in your basket.
            </nuxt-link>

        </div>

        <Button
            v-if="showCheckoutButton"
            text="Checkout"
            class="w-full text-center my-10"
            @click="$router.push('/checkout')"
        />
    </div>

</template>

<script>
import debounce from 'debounce';
import Spinner from '~/components/Spinner';
import MinusIcon from '~/static/fonts/LineIcons/minus.svg';
import PlusIcon from '~/static/fonts/LineIcons/plus.svg';
import Button from './Button';

export default {

    components: {
        Spinner,
        MinusIcon,
        PlusIcon,
        Button,
    },

    props: {
        productId: {
            type: Number,
            required: true,
        },
        productVariationsCompound: {
            type: String,
            default: null,
        },
        productTextVariation: {
            type: String,
            default: null,
        },
        quantity: {
            type: Number,
            required: true,
        },
        showText: {
            type: Boolean,
            default: true,
        },
        showCheckoutButton: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            quantity_: 1,
        };
    },

    watch: {
        quantity(newValue) {
            // Watch the quantity prop and set the internal quantity value to it when it changes.
            this.quantity_ = newValue;
        },
    },

    created() {
        this.quantity_ = this.quantity;
    },

    methods: {
        async updateQuantity(value) {
            // Update the internal quantity value, preventing less than 0.
            this.quantity_ = Math.max(value, 0);
            // Call the debounced method.
            await this.setItemQuantityDebounced();
        },
        setItemQuantityDebounced: debounce(async function _() {
            this.isLoading = true;
            await this.$store.dispatch('basket/updateItem', {
                productId: this.productId,
                productVariationsCompound: this.productVariationsCompound,
                productTextVariation: this.productTextVariation,
                quantity: this.quantity_,
            });
            this.isLoading = false;
        }, 500),
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    @apply inline-flex h-10;
}
.button {
    @apply bg-primary text-white cursor-pointer transition-fast p-2 w-10;
    &:disabled {
        @apply pointer-events-none opacity-50;
    }
    &:hover {
        @apply opacity-75 shadow-md;
    }
}
.input {
    @apply w-full h-full text-center text-primary font-semibold;
    &:focus {
        @apply outline-none;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
.icon {
    @apply h-full;
}
.currently-in-basket-text {
    @apply text-sm font-semibold text-primary no-underline transition-fast;
    &:hover {
        @apply opacity-75;
    }
}
</style>
