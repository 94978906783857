export default {

    steps: (state) => state.steps,
    firstName: (state) => state.steps.firstName,
    lastName: (state) => state.steps.lastName,
    email: (state) => state.steps.email,
    phone: (state) => state.steps.phone,
    giftMessage: (state) => state.steps.giftMessage,
    shippingAddress: (state) => state.steps.shippingAddress,
    billingAddress: (state) => state.steps.billingAddress,
    delivery: (state) => state.steps.delivery,
    payment: (state) => state.steps.payment,
    terms: (state) => state.steps.terms,
    checkProductAvailable: (state) => (id) => {
        const product = state.productsChecked.find((availability) => availability.id === id && availability.available);
        if (product) {
            return product.available;
        }

        return false;
    },
    getDeliveryPrice: (state) => (id) => {
        const product = state.productsChecked.find((availability) => availability.id === id && availability.available);
        if (product) {
            return product.price;
        }

        return 0;
    },
    addressChecked: (state) => (id) => {
        const product = state.productsChecked.find((availability) => availability.id === id);
        return !!product;
    },
    deliveryType: (state) => state.steps.deliveryType.value,
    deliveryDate: (state) => state.steps.delivery.selectedDeliveryDay,
    orderRequest: (state, getters) => ({
        first_name: getters.firstName.value,
        last_name: getters.lastName.value,
        email: getters.email.value,
        phone: getters.phone.value,
        gift_message: getters.giftMessage.value,
        address_first_name: getters.shippingAddress.value.firstName,
        address_last_name: getters.shippingAddress.value.lastName,
        address_line_1: getters.shippingAddress.value.firstLine,
        address_line_2: getters.shippingAddress.value.secondLine,
        address_line_3: getters.shippingAddress.value.thirdLine,
        address_company: getters.shippingAddress.value.company,
        address_city: getters.shippingAddress.value.city,
        address_county: getters.shippingAddress.value.county,
        address_country_iso2: getters.shippingAddress.value.country.iso2,
        address_postcode: getters.shippingAddress.value.postcode,
        billing_address_first_name: getters.billingAddress.value.firstName,
        billing_address_last_name: getters.billingAddress.value.lastName,
        billing_address_line_1: getters.billingAddress.value.firstLine,
        billing_address_line_2: getters.billingAddress.value.secondLine,
        billing_address_line_3: getters.billingAddress.value.thirdLine,
        billing_address_company: getters.billingAddress.value.company,
        billing_address_city: getters.billingAddress.value.city,
        billing_address_county: getters.billingAddress.value.county,
        billing_address_country_iso2: getters.billingAddress.value.country.iso2,
        billing_address_postcode: getters.billingAddress.value.postcode,
        shipping_rate_id: getters.delivery.value,
        ordered_for: getters.delivery.selectedDeliveryDay,
        prefers_boxes: getters.delivery.prefersBoxes,
        delivery_type: getters.deliveryType,
    }),
    transaction: (state) => state.transaction,
    gateway: (state) => state.gateway,
    initiativeContext: (state) => state.initiativeContext,

};
