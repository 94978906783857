import axios from 'axios';

export default async function (context) {
    const client = axios.create();

    const response = await client.get('https://content.bubblegumballoons.com/items/styles', {
        params: {
            fields: [
                '*',
                'text_colour.colour',
                'background_colour.colour',
                'border_colour.colour',
            ],
            limit: -1,
        },
    });

    const styles = [];

    if (Array.isArray(response.data?.data)) {
        response.data.data.forEach((style) => {
            const props = {
                'color': style.text_colour?.colour,
                'background-color': style.background_colour?.colour,
                'text-align': style.text_align,
                'font-size': style.font_size ? `${style.font_size * 0.25}rem` : null,
                'font-weight': style.font_weight,
                'font-family': style.font_family?.name,
                'padding-top': style.padding_top ? `${style.padding_top * 0.25}rem` : null,
                'padding-right': style.padding_right ? `${style.padding_right * 0.25}rem` : null,
                'padding-bottom': style.padding_bottom ? `${style.padding_bottom * 0.25}rem` : null,
                'padding-left': style.padding_left ? `${style.padding_left * 0.25}rem` : null,
                'margin-top': style.margin_top ? `${style.margin_top * 0.25}rem` : null,
                'margin-right': style.margin_right ? `${style.margin_right * 0.25}rem` : null,
                'margin-bottom': style.margin_bottom ? `${style.margin_bottom * 0.25}rem` : null,
                'margin-left': style.margin_left ? `${style.margin_left * 0.25}rem` : null,
                'display': style.display,
                'align-items': style.align_items,
                'justify-content': style.justify_content,
                'flex-direction': style.flex_direction,
                'gap': style.gap ? `${style.gap * 0.25}rem` : null,
                'width': style.width,
                'height': style.height,
                'border-radius': style.border_radius ? `${style.border_radius * 0.5}rem` : null,
                'border-width': style.border_width ? `${style.border_width}px` : null,
                'border-color': style.border_colour?.colour,
            };

            const filteredProps = Object.fromEntries(Object.entries(props).filter(([_, v]) => v != null));

            let rules = Object.keys(filteredProps)
                .map((key) => `${key}:${filteredProps[key] ?? 'inherit'};`)
                .join('');

            if (style.css) {
                rules += style.css.replace(/[\n\s]/g, '');
            }

            styles.push(`.style-${style.id}{${rules}}`);
        });
    }

    const styleElement = document.createElement('style');

    styleElement.id = 'directus-styles';
    styleElement.innerHTML = styles.join('');

    document.head.appendChild(styleElement);
}
