import moment from 'moment';

export default {

    getByKey: (state) => (key) => {
        const value = state.settings.find((setting) => setting.key === key);

        if (value) {
            return value.value;
        }
    },

    maintenanceMode: () => false,

    nextDeliveryDate: (state) => {
        const date = moment(state.nextDeliveryDate);

        if (date.isValid()) {
            return date.format(state.dateFormat);
        }

        return '';
    },

    nextDeliveryCutOff: (state) => state.nextDeliveryCutOff,
    nextDeliveryCutOffCountdown: (state) => state.nextDeliveryCutOffCountdown,
    nextDeliveryCutOffInterval: (state) => state.nextDeliveryCutOffInterval,

    dateFormat: (state) => state.dateFormat,
    noticeCarouselIndex: (state) => state.noticeCarouselIndex,
    noticeCarouselItems: (state, getters) => {
        const notice = getters.getByKey('site_notice');

        if (notice) {
            return notice.split('\r\n');
        }

        return [];
    },
    activeNoticeCarouselItem: (state, getters) => getters.noticeCarouselItems[getters.noticeCarouselIndex],

    deliveryHolidays: (state) => state.deliveryHolidays,
    collectionHolidays: (state) => state.collectionHolidays,

    holidays(state, getters, rootState, rootGetters) {
        if (rootGetters['checkout/deliveryType'] === 'collection') {
            return getters.collectionHolidays;
        }

        return getters.deliveryHolidays;
    },

    flatPickrConfig(state, getters, rootState, rootGetters) {
        return {
            inline: true,
            minDate: moment(state.nextDeliveryDate).toDate(),
            maxDate: moment().add(6, 'months').toDate(),
            enableTime: false,
            dateFormat: 'Y-m-d',
            locale: {
                firstDayOfWeek: 1,
            },
            disable: getters.holidays,
        };
    },

    availableRates: (state) => state.availableRates,

    localDeliveryOnly(state, getters) {
        return getters.availableRates.filter((rate) => rate.name.includes('DHL')).length === 0;
    },

    isMobile: (state) => state.isMobile,

};
