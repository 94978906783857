<template>
    <div class="grid gap-4">
        <a
            v-for="post in posts.slice(0, 3)"
            :key="`post-${post.id}`"
            :href="post.link"
            class="grid grid-cols-6 gap-4"
        >
            <div v-if="post._embedded['wp:featuredmedia']">
                <img
                    :src="post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url"
                    alt=""
                >
            </div>
            <div class="col-span-5">
                <h4
                    class="font-semibold"
                    v-html="post.title.rendered"
                />
                <div v-html="post.excerpt.rendered" />
            </div>
        </a>
    </div>
</template>

<script>
export default {
    computed: {
        posts() {
            return this.$store.getters['content/blogPosts'];
        },
    },
    mounted() {
        this.$store.dispatch('content/loadBlogPosts');
    },
};
</script>

<style scoped>

</style>
