/**
 * Our initial store state. Used to 'reset' the store.
 */
export default {

    steps: {
        firstName: {
            value: '',
            active: true,
            disabled: false,
            readonly: false,
        },
        lastName: {
            value: '',
            active: false,
            disabled: true,
            readonly: false,
        },
        email: {
            value: '',
            canOptIntoNewsletter: true,
            isOptedIntoNewsletter: false, // false by default for GDPR
            active: false,
            disabled: true,
            readonly: false,
        },
        phone: {
            value: '',
            active: false,
            disabled: true,
            readonly: false,
        },
        giftMessage: {
            value: '',
            active: false,
            disabled: true,
            readonly: false,
        },
        shippingAddress: {
            value: {
                firstName: '',
                lastName: '',
                firstLine: '',
                secondLine: '',
                thirdLine: '',
                company: '',
                city: '',
                county: '',
                country: {},
                postcode: '',
            },
            showAddressLookup: true,
            active: false,
            disabled: true,
            readonly: false,
        },
        billingAddress: {
            value: {
                firstName: '',
                lastName: '',
                firstLine: '',
                secondLine: '',
                thirdLine: '',
                company: '',
                city: '',
                county: '',
                country: {
                    iso2: '',
                },
                postcode: '',
            },
            showAddressLookup: true,
            active: false,
            disabled: true,
            readonly: false,
        },
        delivery: {
            value: null,
            selectedDeliveryDay: null,
            availableDeliveryOptions: [],
            availableDeliveryOptionsError: '',
            active: false,
            disabled: true,
            readonly: false,
            prefersBoxes: false,
        },
        terms: {
            active: false,
            disabled: true,
        },
        payment: {
            active: false,
            disabled: true,
            isProcessing: false,
            method: null,
        },
        deliveryType: {
            value: null,
            active: false,
            disabled: true,
            readonly: false,
        },
    },

    productsChecked: [],

    transaction: {},

    gateway: null,

    initiativeContext: null,

};
