<script>

import { email, required } from 'vuelidate/lib/validators';
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue';
import handleException from '@/plugins/error-handler.client';

export default {
    name: 'NewsletterSubscribe',
    components: { Input, Button },
    data() {
        return {
            email: '',
            isDone: false,
        };
    },

    computed: {
        user() {
            return this.$store.getters['user/user'];
        },
        isLoggedIn() {
            return this.$store.getters['user/isLoggedIn'];
        },
        isUserSubscribedToNewsletter() {
            return this.user.is_opted_into_newsletter;
        },
    },

    methods: {
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) return;
            this.$axios.$post('/newsletter/subscribe', {
                email: this.email,
            })
                .then(() => {
                    this.isDone = true;
                })
                .catch((error) => {
                    handleException(error);
                });
        },
        subscribeUser() {
            this.$axios.$post('/newsletter/subscribe', {
                email: this.user.email,
                first_name: this.user.first_name,
                last_name: this.user.last_name,
            })
                .then(() => {
                    this.isDone = true;
                })
                .catch((error) => {
                    handleException(error);
                });
        },
    },

    validations: {
        email: {
            required,
            email,
        },
    },
};
</script>

<template>
    <div>
        <div v-if="isLoggedIn">
            <div
                v-if="isUserSubscribedToNewsletter || isDone"
                class="text-sm"
            >
                You are already subscribed!
            </div>
            <div
                v-else
                class="flex justify-center w-full"
            >
                <Button
                    class="py-3 px-4"
                    text="Sign Up"
                    @click="subscribeUser"
                />
            </div>
        </div>
        <div
            v-else-if="isDone"
            class="text-primary text-sm"
        >
            Thank you for signing up.
        </div>
        <div
            v-else
            class="mt-2"
        >
            <div class="flex items-center gap-4">
                <Input
                    v-model="email"
                    :invalid="$v.email.$error"
                    class="w-full"
                    type="email"
                    placeholder="Enter your email address"
                    @enter="submit"
                />
                <Button
                    class="py-3 px-4"
                    bg="pink-500"
                    text="Subscribe"
                    @click="submit"
                />
            </div>

            <div class="text-xs mt-1">
                By subscribing, you agree to our
                <nuxt-link
                    class="inline-block"
                    to="/terms-and-conditions"
                >
                    Terms
                </nuxt-link>
                .
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.small {
    transform: scale(0.85);
}
</style>
