<template>
    <div>
        <HeaderNotice />
        <HeaderMain class="hidden lg:block" />
        <HeaderNav class="hidden lg:block" />
        <HeaderMainMobile class="lg:hidden" />
    </div>
</template>

<script>
import HeaderMain from '~/components/HeaderMain';
import HeaderMainMobile from '~/components/HeaderMainMobile';
import HeaderNav from '~/components/HeaderNav';
import HeaderDelivery from '~/components/HeaderDelivery';
import HeaderNotice from './HeaderNotice';

export default {

    components: {
        HeaderMain,
        HeaderMainMobile,
        HeaderNav,
        HeaderDelivery,
        HeaderNotice,
    },

};
</script>

<style lang="scss" scoped>
    .tassels {
        @apply block w-full;
    }
</style>
