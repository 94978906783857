<template>
    <div

        :class="{ 'wrapper__hidden' : !megaMenuOpen }"
        class="wrapper"
    >

        <div
            :style="{ left: `${caretPos}px` }"
            class="caret"
        />

        <div
            class="container mx-auto flex"
            :class="`justify-${item.align}`"
        >
            <Grid
                v-for="(grid, index) in item.grids"
                :key="index"
                :grid="grid.grids_id"
                class="p-10 bg-pink-100"
                :style="{width: `${item.width}%`}"
            />
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Markdown from './Markdown';
import HeaderNavLink from './HeaderNavLink';
import Grid from '@/components/pages/Grid.vue';

export default {
    components: {
        Grid,
        HeaderNavLink,
        Markdown,
    },

    props: {
        thumbs: {
            type: Array,
            default: () => ([]),
        },
        caretPos: {
            type: Number,
            default: null,
        },
        item: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        ...mapGetters('content', [
            'megaMenuOpen',
        ]),
    },

    methods: {
        emitClose() {
            this.$emit('close');
        },
    },

};
</script>

<style lang="scss" scoped>
.wrapper {
    @apply absolute z-30 overflow-hidden transition-slow text-sm;
    top: 100%;
    left: 0;
    right: 0;
    &__hidden {
        @apply opacity-0 pointer-events-none;
    }
}
a {
    @apply no-underline transition-property-none transition-property-color;
    &:hover {
        @apply text-primary;
    }
}
.caret {
    @apply absolute shadow w-10 h-10 bg-pink-200 transition-fast;
    transform: translateY(-70%) translateX(-50%) rotate(45deg);
}
</style>
