<template>
    <div
        class="flex items-center leading-none opacity-0"
        :class="{ 'opacity-100' : nextDeliveryDate }"
    >
        <DeliveryIcon class="w-8 mr-3" />
        <div>
            <div class="font-semibold">
                {{ localDeliveryOnly ? 'UK' : '' }}
                {{ getByKey('next_day_delivery_banner_text') }} &nbsp;
            </div>
            <div>{{ nextDeliveryDate }} &nbsp;</div>
            <div class="text-xs font-normal text-pink-lighter mt-1">
                {{ nextDeliveryCutOffCountdown }} &nbsp;
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeliveryIcon from '~/static/fonts/LineIcons/delivery.svg';

export default {
    components: {
        DeliveryIcon,
    },

    computed: {
        ...mapGetters('settings', [
            'getByKey',
            'nextDeliveryDate',
            'nextDeliveryCutOff',
            'nextDeliveryCutOffCountdown',
            'localDeliveryOnly',
        ]),
    },

    mounted() {
        this.start();
    },

    methods: {
        start() {
            this.$store.dispatch('settings/getNextDeliveryDate');
        },
    },

};
</script>

<style scoped>
</style>
