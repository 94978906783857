<template>
    <modal
        name="first-visit-newsletter-modal"
        width="430"
        height="auto"
        scrollable
        adaptive
        @before-close="setLocalStorage"
    >
        <div class="gradient p-10 pb-6">

            <CloseIcon
                class="close-icon"
                @click="closeModal"
            />

            <img
                v-lazy="imageUrl"
                class="mb-6"
                alt="Newsletter Discount Promotion"
            >

            <Input
                v-model="email"
                :invalid="$v.email.$error"
                class="mb-6 border border-grey-darker rounded"
                type="email"
                placeholder="Enter your email"
                focused
                @enter="submit"
            />

            <Button
                class="w-full mb-4"
                text="Sign Up"
                @click="submit"
            />

            <div class="text-xs text-grey-darkest opacity-50">
                By subscribing, you agree to our <nuxt-link
                    class="inline-block"
                    to="/terms-and-conditions"
                    @click.native="closeModal"
                >
                    Terms
                </nuxt-link>.
            </div>

        </div>
    </modal>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import Input from '@/components/Input';
import Button from '@/components/Button';
import CloseIcon from '@/static/fonts/LineIcons/close.svg';
import handleException from '../plugins/error-handler.client';

export default {

    components: {
        Input,
        Button,
        CloseIcon,
    },

    data() {
        return {
            email: '',
        };
    },

    computed: {
        alreadyDisplayed() {
            return !!localStorage.getItem('dismissed-newsletter-modal-at-time');
        },
        imageUrl() {
            return this.$store.getters['settings/getByKey']('newsletter_modal_image') || '/images/10-percent-discount.png';
        },
    },

    mounted() {
        if (!this.alreadyDisplayed) {
            this.openModal();
        }
    },

    methods: {
        openModal() {
            this.$modal.show('first-visit-newsletter-modal');
        },
        closeModal() {
            this.$modal.hide('first-visit-newsletter-modal');
            this.setLocalStorage();
        },
        setLocalStorage() {
            localStorage.setItem('dismissed-newsletter-modal-at-time', new Date().getTime());
        },
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) return;
            this.$axios.post('/newsletter/subscribe', {
                email: this.email,
            })
                .then(this.closeModal)
                .catch((error) => {
                    this.closeModal();
                    handleException(error);
                });
        },
    },

    validations: {
        email: {
            required,
            email,
        },
    },

};
</script>

<style lang="scss" scoped>
.gradient {
    background-image: url('/images/newsletter-background.jpg');
    @apply bg-center bg-cover;
}
.close-icon {
    @apply absolute h-5 p-4 cursor-pointer transition-fast;
    box-sizing: content-box;
    top: 0;
    right: 0;
    &:hover {
        @apply opacity-50;
    }
}
</style>
