import Swal from 'sweetalert2';

const handleException = (error, message = null, title = null) => {
    Swal.fire({
        icon: 'error',
        title: title || 'Sorry about this...',
        text: message || 'Looks like something went wrong there. Please try again or get in touch with customer support if you have any further issues.',
    });
};

export default handleException;
