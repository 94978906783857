<template>
    <div class="px-4 help-article">
        <div
            v-if="article?.content?.includes('newsletter')"
            class="mb-4"
        >
            <h2>Subscribe To Our Newsletter</h2>
            <NewsletterSubscribe />
        </div>

        <markdown
            v-if="article && article.content"
            :markdown="article.content"
        />

        <ShippingClassAddressChecker
            v-if="article.shipping_class_id"
            :shipping-class-id="article.shipping_class_id"
        />
    </div>
</template>

<script>
import Markdown from '~/components/Markdown';
import ShippingClassAddressChecker from '@/components/ShippingClassAddressChecker.vue';
import NewsletterSubscribe from '@/components/forms/NewsletterSubscribe.vue';

export default {
    components: {
        NewsletterSubscribe,
        ShippingClassAddressChecker,
        Markdown,
    },
    props: {
        article: {
            type: Object,
            required: true,
        },
    },
};
</script>
