import moment from 'moment';

export default {

    async getSettings({ commit }) {
        const settings = await this.$axios.$get('settings');
        commit('updateSettings', { settings });
    },

    async getNextDeliveryDate({ commit, dispatch, getters }) {
        const delivery = await this.$axios.$get('/next-day-delivery-date');
        commit('SET_NEXT_DELIVERY_DATE', delivery.date);
        commit('SET_NEXT_DELIVERY_CUT_OFF', delivery.cutOff);
        commit('SET_COLLECTION_HOLIDAYS', delivery.collectionHolidays);
        commit('SET_DELIVERY_HOLIDAYS', delivery.deliveryHolidays);
        commit('SET_AVAILABLE_RATES', delivery.availableRates);

        clearInterval(getters.nextDeliveryCutOffInterval);
        commit('SET_NEXT_DELIVERY_CUT_OFF_INTERVAL', setInterval(() => dispatch('updateCutOffCountdown'), 1000));
    },

    updateCutOffCountdown({ commit, getters }) {
        const cutOff = moment(getters.nextDeliveryCutOff);
        const duration = moment.duration(cutOff.diff(moment()));
        const hours = parseInt(duration.asHours(), 10);
        const minutes = parseInt(duration.asMinutes(), 10) % 60;
        const seconds = parseInt(duration.asSeconds(), 10) % 60;

        if (duration.asSeconds() <= 0) {
            clearInterval(getters.nextDeliveryCutOffInterval);
            commit('SET_NEXT_DELIVERY_CUT_OFF_COUNTDOWN', '');
            return;
        }

        let string = 'Order within ';

        if (hours > 0) {
            string += `${hours}h `;
        }

        if (minutes > 0 || hours > 0) {
            string += `${minutes}m `;
        }

        string += `${seconds}s`;

        commit('SET_NEXT_DELIVERY_CUT_OFF_COUNTDOWN', string);
    },

    incrementNoticeCarouselIndex({ commit, getters }) {
        commit('SET_NOTICE_CAROUSEL_INDEX', (getters.noticeCarouselIndex + 1) % getters.noticeCarouselItems.length);
    },

    setIsMobile({ commit }, value) {
        commit('SET_IS_MOBILE', value);
    },

};
