<script>
import Markdown from '~/components/Markdown.vue';

export default {
    components: {
        Markdown,
    },
    props: {
        component: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<template>
    <Markdown
        :class="`style-${component.style}`"
        :markdown="component.content"
    />
</template>

<style scoped lang="scss">

</style>
