/**
 * Our initial store state. Used to 'reset' the store.
 */
export default {

    id: null,
    hash: null,
    code: null,
    items: [],
    itemsPrice: '0.00',
    shippingPrice: '0.00',
    discountSavings: '0.00',
    totalPrice: '0.00',
    totalTax: '0.00',
    discountCode: {
        name: null,
    },
    orderedFor: null,
    order: {},
    clickPlus: false,

};
