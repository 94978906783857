<template>
    <component
        :is="is"
        :to="item.url"
        :href="item.url"
        :class="itemClass"
        class="transition-opacity duration-500"
    >
        <ItemComponent
            v-for="component in item.components"
            :key="`item-${component.id}`"
            :component="component"
        />
    </component>
</template>

<script>
import ItemComponent from '~/components/pages/components/ItemComponent.vue';

export default {
    components: {
        ItemComponent,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        isMobile() {
            return this.$store.getters['settings/isMobile'];
        },
        itemClass() {
            const classes = [
                `style-${this.item.style}`,
                `col-span-${this.item.column_span_mobile || 1}`,
                `row-span-${this.item.row_span_mobile || 1}`,
                `md:col-span-${this.item.column_span || 1}`,
                `md:row-span-${this.item.row_span || 1}`,
                `md:row-span-${this.item.row_span || 1}`,
                `md:col-start-${this.item.start_column}`,
            ];

            if (this.loading) {
                classes.push('opacity-0');
            }

            if (this.item.display_on === 'mobile') {
                classes.push('md:hidden');
            } else if (this.item.display_on === 'desktop') {
                classes.push('hidden md:block');
            }

            return classes;
        },
        is() {
            if (!this.item.url) {
                return 'div';
            }

            if (this.item.url.includes('http')) {
                return 'a';
            }

            return 'nuxt-link';
        },
    },
    mounted() {
        this.loading = false;
    },
    methods: {
        goToPrev() {
            this.$refs.carousel.prev();
        },
        goToNext() {
            this.$refs.carousel.next();
        },
    },
};
</script>

