<template>
    <main>

        <SlideMenu>
            <Header />
            <nuxt :key="pageKey" />
            <Footer />
        </SlideMenu>

        <client-only>
            <MobileSearch />
            <AuthModal />
        </client-only>

        <ScrollToTop />
    </main>
</template>

<script>
/**
 * The default layout containing the header (with mobile search),
 * newsletter modal, auth modal and scroll to top button.
 */
import SlideMenu from '~/components/SlideMenu';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import MobileSearch from '~/components/MobileSearch';
import AuthModal from '~/components/AuthModal';
import ScrollToTop from '~/components/ScrollToTop';
import FirstVisitNewsletterModal from '@/components/FirstVisitNewsletterModal.vue';

export default {

    components: {
        FirstVisitNewsletterModal,
        SlideMenu,
        Header,
        Footer,
        MobileSearch,
        AuthModal,
        ScrollToTop,
    },

    data() {
        return {
            pageKey: 0,
        };
    },

    computed: {
        isLoggedIn() {
            return this.$store.getters['user/isLoggedIn'];
        },
        canonical() {
            const canonical = process.env.baseUrl + this.$nuxt.$route.path;
            return canonical.replace('//$/', '');
        },
    },

    created() {
        this.registerRefreshPageEventHandler();
        this.$store.dispatch('settings/getSettings');
    },

    methods: {
        /**
         * We use this method to refresh the page from anywhere in the app using the $nuxt.
         * This allows us to, for example, handle prefilling the checkout details (on checkout page load)
         * when logging in when already on the checkout page.
         */
        registerRefreshPageEventHandler() {
            this.$nuxt.$on('refresh-page', this.incrementPageKey);
        },
        /**
         * This method will increment (arbitrary) the nuxt component key.
         * This is a VueJS "trick" which we can use to re-mount a component, restarting it's lifecycle.
         */
        incrementPageKey() {
            this.pageKey += 1;
        },
    },

    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.canonical,
                },
            ],
        };
    },

};
</script>

<style lang="scss" scoped>
</style>
