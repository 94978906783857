<script>
export default {
    props: {
        component: {
            type: Object,
            default: () => {},
        },
        fit: {
            type: String,
            default: 'inherit',
        },
        width: {
            type: Number,
            default: 512,
        },
    },
    computed: {
        widthConstraint() {
            return this.component.width ?? this.width;
        },
        quality() {
            return this.component.quality ?? 60;
        },
    },
    methods: {
        src(image) {
            if (image.directus_files_id) {
                return `https://content.bubblegumballoons.com/assets/${image.directus_files_id.id}?format=webp&quality=${this.quality}&width=${this.widthConstraint}`;
            }

            return null;
        },
        imgProps(image) {
            return {
                alt: image.alt,
                width: image.directus_files_id.width,
                height: image.directus_files_id.height,
                class: `style-${this.component.style}`,
                style: {
                    objectFit: this.fit,
                    width: this.fit !== 'inherit' ? '100%' : null,
                    height: this.fit !== 'inherit' ? '100%' : null,
                },
            };
        },
    },

};
</script>

<template>
    <div>
        <template
            v-for="image in component.sizes"
        >
            <img
                v-if="component.lazy_load"
                :key="image.id"
                v-lazy="src(image)"
                v-bind="imgProps(image)"
            >
            <img
                v-else
                :key="image.id"
                :src="src(image)"
                v-bind="imgProps(image)"
            >
        </template>
    </div>
</template>

<style scoped lang="scss">

</style>
