<template>
    <div :class="`style-${grid.style}`">
        <div
            :class="gridClasses"
        >
            <Item
                v-for="item in grid.items"
                :key="`item-${item.id}`"
                :item="item"
            />
        </div>
    </div>

</template>

<script>
import Item from './Item';

export default {
    components: {
        Item,
    },
    props: {
        grid: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        gridClasses() {
            const classes = [
                'grid',
                `grid-cols-${this.grid.columns_mobile}`,
                `md:grid-cols-${this.grid.columns_desktop}`,
                `gap-${this.grid.item_gap}`,
                this.grid.contain ? 'container mx-auto px-4 md:px-0' : '',
            ];

            if (this.grid.display_on === 'mobile') {
                classes.push('md:hidden');
            } else if (this.grid.display_on === 'desktop') {
                classes.push('hidden md:block');
            }

            return classes;
        },
    },
};
</script>

<style scoped>

</style>
