<template>
    <div>
        <div class="flex justify-between mb-5">
            <div class="w-5/6">
                <h2 class="font-normal">
                    Register
                </h2>
            </div>
            <div class="w-5">
                <CloseIcon
                    class="close-icon mt-1"
                    @click="closeModal"
                />
            </div>
        </div>

        <div class="flex flex-wrap -mx-3">
            <div class="w-full md:w-1/2 px-3">
                <TextInput
                    v-model="firstName"
                    :invalid="$v.firstName.$error"
                    class="mb-5"
                    label="First Name"
                    focused
                >
                    <template #errors>
                        <div v-if="!$v.firstName.required && $v.firstName.$dirty">
                            Please enter your first name.
                        </div>
                    </template>
                </TextInput>
            </div>
            <div class="w-full md:w-1/2 px-3">
                <TextInput
                    v-model="lastName"
                    :invalid="$v.lastName.$error"
                    class="mb-5"
                    label="Last Name"
                >
                    <template #errors>
                        <div v-if="!$v.lastName.required && $v.lastName.$dirty">
                            Please enter your last name.
                        </div>
                    </template>
                </TextInput>
            </div>
        </div>

        <div class="flex flex-wrap -mx-3">
            <div class="w-full md:w-1/2 px-3">
                <TextInput
                    v-model="email_"
                    :invalid="$v.email_.$error || !!emailErrorMessage"
                    type="email"
                    class="mb-5"
                    label="Email"
                >
                    <template #errors>
                        <div v-if="!$v.email_.required && $v.email_.$dirty">
                            Please enter your email address.
                        </div>
                        <div v-if="!$v.email_.email && $v.email_.$dirty">
                            Please enter a valid email address.
                        </div>
                        <div v-if="emailErrorMessage">
                            {{ emailErrorMessage }}
                        </div>
                    </template>
                </TextInput>
            </div>
            <div class="w-full md:w-1/2 px-3">
                <TextInput
                    v-model="phone"
                    :invalid="$v.phone.$error"
                    type="tel"
                    class="mb-5"
                    label="Phone"
                >
                    <template #errors>
                        <div v-if="!$v.phone.required && $v.phone.$dirty">
                            Please enter your phone number.
                        </div>
                    </template>
                </TextInput>
            </div>
        </div>

        <div class="flex flex-wrap -mx-3">
            <div class="w-full md:w-1/2 px-3">
                <TextInput
                    v-model="password"
                    :invalid="$v.password.$error || !!passwordErrorMessage"
                    type="password"
                    class="mb-5"
                    label="Password"
                >
                    <template #errors>
                        <div v-if="!$v.password.required && $v.password.$dirty">
                            Please enter your password.
                        </div>
                        <div v-if="passwordErrorMessage">
                            {{ passwordErrorMessage }}
                        </div>
                    </template>
                </TextInput>
            </div>
            <div class="w-full md:w-1/2 px-3">
                <TextInput
                    v-model="passwordConfirm"
                    :invalid="$v.passwordConfirm.$error"
                    type="password"
                    class="mb-5"
                    label="Confirm Password"
                    @enter="submit"
                >
                    <template #errors>
                        <div v-if="!$v.passwordConfirm.required && $v.passwordConfirm.$dirty">
                            Please confirm your password.
                        </div>
                        <div v-if="!$v.passwordConfirm.sameAsPassword && $v.passwordConfirm.$dirty">
                            Please make sure your passwords match.
                        </div>
                    </template>
                </TextInput>
            </div>
        </div>

        <div class="text-xs text-grey-dark mb-5">
            By registering, you agree to our <nuxt-link
                class="inline-block"
                to="/terms-and-conditions"
            >
                Terms
            </nuxt-link>.
        </div>

        <div class="flex flex-wrap items-center mb-32 sm:mb-0">
            <Button
                text="Register"
                @click="submit"
            />
            <div
                class="text w-full md:w-auto mt-4 md:mt-0 md:ml-6 "
                @click="changeComponent('AuthModalSignIn')"
            >
                Already have an account? <span class="font-semibold">Sign In</span>.
            </div>
        </div>

    </div>
</template>

<script>
import { required, email, sameAs } from 'vuelidate/lib/validators';
import TextInput from '~/components/Input';
import Button from '~/components/Button';
import CloseIcon from '~/static/fonts/LineIcons/close.svg';

export default {

    components: {
        TextInput,
        Button,
        CloseIcon,
    },

    props: {
        email: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            firstName: '',
            lastName: '',
            email_: '',
            phone: '',
            password: '',
            passwordConfirm: '',
            emailErrorMessage: null,
            passwordErrorMessage: null,
        };
    },

    watch: {
        email_() {
            this.emailErrorMessage = null;
        },
        password() {
            this.passwordErrorMessage = null;
        },
    },

    created() {
        this.email_ = this.email;
    },

    methods: {
        changeComponent(component) {
            this.$emit('change-component', {
                component,
                email: this.email_,
            });
        },
        async submit() {
            this.emailErrorMessage = null;
            this.passwordErrorMessage = null;
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.$axios.$post('/customer/register', {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email_,
                phone: this.phone,
                password: this.password,
            })
                .then(async (response) => {
                    await this.$auth.setUserToken(response.token);
                    this.$modal.hide('auth-modal');
                    this.$store.dispatch('user/setUser', this.$auth.user);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        if (error.response.data.errors.email) {
                            /* eslint-disable-next-line */
                            this.emailErrorMessage = error.response.data.errors.email[0];
                        }
                        if (error.response.data.errors.password) {
                            /* eslint-disable-next-line */
                            this.passwordErrorMessage = error.response.data.errors.password[0];
                        }
                    }
                });
        },
        closeModal() {
            this.$modal.hide('auth-modal');
        },
    },

    validations: {
        firstName: {
            required,
        },
        lastName: {
            required,
        },
        email_: {
            required,
            email,
        },
        phone: {
            required,
        },
        password: {
            required,
        },
        passwordConfirm: {
            required,
            sameAsPassword: sameAs('password'),
        },
    },

};
</script>

<style lang="scss" scoped>
.text {
    @apply text-primary transition-fast cursor-pointer text-sm select-none;
    &:hover {
        @apply opacity-50;
    }
}

.close-icon {
    @apply h-5 cursor-pointer transition-fast;
    &:hover {
        @apply opacity-50;
    }
}
</style>
