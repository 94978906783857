/**
 * Redirects to the home page if the basket is empty.
 * This runs on the checkout page.
 */
const RedirectToHomeIfBasketIsEmpty = function ({ store, redirect }) {
    if (process.client && store.getters['basket/itemCount'] === 0) {
        return redirect('/');
    }
};

export default RedirectToHomeIfBasketIsEmpty;
