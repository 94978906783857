<template>
    <div>
        <h1 class="mb-4 text-5xl">
            404
        </h1>
        <h1 class="mb-8 text-3xl">
            Page not found.
        </h1>
        <div class="text-lg">
            Sorry, we couldn't find what you were looking for. Why not try searching for something else?
        </div>

        <div
            v-if="products.length"
            class="mt-10 flex flex-wrap -mx-4 -mb-5"
        >
            <div
                v-for="(product, index) in products"
                :key="index"
                class="w-full md:w-1/5 px-4 mb-5"
            >
                <ProductCard
                    :product="product"
                    :position="index"
                    list="Page not found"
                />
            </div>
        </div>

    </div>
</template>

<script>
import ProductCard from '@/components/ProductCard';

export default {

    components: {
        ProductCard,
    },

    data() {
        return {
            products: [],
        };
    },

    mounted() {
        this.focusSearch();
        this.fetchMostPopularProducts();
    },

    methods: {
        focusSearch() {
            this.$nuxt.$emit('focus-search');
        },
        async fetchMostPopularProducts() {
            try {
                this.products = await this.$axios.$get('/products/most-popular');
            } catch (error) {
                this.$sentry.captureException(error);
            }
        },
    },

};
</script>

<style lang="scss" scoped>
.input {
    width: 750px;
}
</style>
