export default {
    productVariations: (state) => state.productVariations,
    text: (state) => state.text,
    textLine2: (state) => state.textLine2,
    fonts: (state) => state.fonts,
    fontId: (state) => state.fontId,
    fontFamily: (state) => {
        const selectedFont = state.fonts.find((font) => font.id === state.fontId);

        return selectedFont?.name ?? 'Mellony Dry Brush';
    },
    customisationImage: (state) => state.customisationImage,
    defaultImage: (state) => state.defaultImage,
    customerImagePreview: (state) => state.customerImagePreview,
    customerImageUrl: (state) => state.customerImageUrl,
    customerImageWidth: (state) => state.customerImageWidth,
    customerImageHeight: (state) => state.customerImageHeight,
    textVariation(state, getters) {
        const textVariations = Object.values(getters.productVariations ?? {}).filter((type) => type.text);

        return textVariations[0]?.variations[0] ?? {};
    },
    showProductCustomisationImage: (state) => state.showProductCustomisationImage,
    isVariationImage: (state) => state.isVariationImage,
    showStockNotification: (state) => state.showStockNotification,
    isBuyButtonLoading: (state) => state.isBuyButtonLoading,
    canvasPreview: (state) => state.canvasPreview,
    totalPrice: (state) => state.totalPrice,
    quantity: (state) => state.quantity,
    selectedVariations: (state) => state.selectedVariations,
    selectedVariationIds: (state) => state.selectedVariations.map((variation) => variation.ids).flat(1),
    activeVariationImage: (state) => state.activeVariationImage,
    customisationLayers: (state, getters) => {
        const layers = [];

        getters.productVariations.forEach((variationType) => {
            const selectedVariations = getters.selectedVariationIds.flatMap((id) => variationType.variations.filter((variation) => variation.id === id));

            const selectedVariationCount = selectedVariations.length;

            selectedVariations.forEach((variation, index) => {
                if (variation.layer_image?.sizes?.large) {
                    const width = selectedVariationCount * 75;

                    const offset = selectedVariationCount > 1
                        ? (index - (selectedVariationCount - 1) / 2) * (Math.min(width, 300) / (selectedVariationCount - 1))
                        : 0;

                    layers.push({
                        layer_position: variationType.layer_position,
                        image: variation.layer_image.sizes.large,
                        offset: offset * -1 + (selectedVariationCount > 1 ? 75 : 0),
                    });
                }
            });
        });

        return layers.sort((a, b) => a.layer_position - b.layer_position);
    },

};
