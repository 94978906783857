<template>
    <div class="mb-8">

        <nuxt-link
            :to="link"
            class="no-underline hover:text-primary transition-fast block mb-3 leading-tight"
        >
            <h4
                class="name"
                @click="$emit('click')"
            >
                {{ item.product.name }}
            </h4>
        </nuxt-link>

        <div class="flex">

            <nuxt-link
                :to="link"
                class="w-1/3"
            >
                <div
                    v-if="item.images && item.images.length > 1"
                    class="grid grid-cols-2 gap-2"
                >
                    <img
                        v-for="(image, index) in item.images"
                        :key="index"
                        v-lazy="image"
                        alt=""
                        class="image"
                    >
                </div>
                <img
                    v-else
                    v-lazy="backgroundImageUrl"
                    class="image"
                    @click="$emit('click')"
                >
            </nuxt-link>

            <div class="ml-5 flex flex-col items-end gap-4 w-2/3">
                <div>
                    <ul
                        v-if="customisations.length > 0"
                        class="text-sm mt-1 text-grey-dark flex flex-col items-end"
                    >
                        <li v-for="(customisation, index) in customisations" :key="`customisation-${index}`">
                            {{ customisation }}
                        </li>
                    </ul>
                    <div
                        v-if="item.textVariation"
                        class="text-sm mt-1 text-grey-dark"
                    >
                        "{{ item.textVariation }}"
                    </div>
                    <div
                        v-if="item.textVariationLine2"
                        class="text-sm mt-1 text-grey-dark"
                    >
                        "{{ item.textVariationLine2 }}"
                    </div>
                </div>
                <ProductQuantityInput
                    :product-id="item.product.id"
                    :quantity="item.quantity"
                    :product-variations-compound="item.productVariationsCompound"
                    :product-text-variation="item.textVariation"
                    :show-text="false"
                    class="quantity-small"
                />
                <div class="flex items-center mb-3">
                    <span class="text-lg font-semibold mr-3">£{{ item.total }}</span>
                    <span
                        v-show="item.quantity > 1"
                        class="text-sm whitespace-nowrap"
                    >(£{{ item.price }} x {{ item.quantity }})</span>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import ProductQuantityInput from '~/components/ProductQuantityInput';

export default {

    components: {
        ProductQuantityInput,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    computed: {
        link() {
            return `/products/${this.item.product.id}/${this.item.product.slug}`;
        },
        backgroundImageUrl() {
            return this.item.canvasPreview || this.item.imageUrl;
        },
        customisations() {
            const variations = this.item.variations.filter((variation) => !variation.is_text).map((variation) => {
                let string = variation.basket_name;
                if (variation.price > 0) {
                    string = `${variation.basket_name} (+£${variation.offer_price !== null ? variation.offer_price : variation.price})`;
                }
                return string;
            });

            const addOns = this.item.addOns?.filter((addOn) => addOn.quantity > 0).map((addOn) => {
                let string = addOn.name;
                if (addOn.price > 0) {
                    string = `${addOn.quantity} x ${addOn.name} (+£${addOn.price})`;
                }
                return string;
            })

            return variations.concat(addOns).filter((value) => value);
        },
        customisationsString() {
            return this.customisations.join(', ');
        },
    },

};
</script>

<style lang="scss" scoped>
.image {
    @apply rounded transition-fast w-full;
    &:hover {
        @apply opacity-75;
    }
}
.name {
    @apply inline-block font-semibold;
}
.quantity-small {
    transform: scale(0.8);
    transform-origin: right top;
}
</style>
