<template>
    <div class="bg-pink-400 text-white">

        <div class="container mx-auto py-5 -mb-5">
            <div class="flex flex-wrap text-center">

                <div class="w-full lg:w-1/3 px-10 mb-5">
                    <FooterSocialSocial />
                </div>

                <div class="w-full lg:w-1/3 px-10 mb-5">
                    <FooterSocialAboutUs />
                </div>

                <div class="w-full lg:w-1/3 px-10 mb-5 text-center">
                    <client-only>
                        <FooterSocialNewsletter />
                    </client-only>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import FooterSocialSocial from '~/components/FooterSocialSocial';
import FooterSocialAboutUs from '~/components/FooterSocialAboutUs';
import FooterSocialNewsletter from '~/components/FooterSocialNewsletter';

export default {

    components: {
        FooterSocialSocial,
        FooterSocialAboutUs,
        FooterSocialNewsletter,
    },

};
</script>

<style scoped>
.background {
    background-color: #fef5f6;
}
</style>
