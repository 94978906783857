<script>
import ProductCard from '@/components/ProductCard.vue';
import handleException from '@/plugins/error-handler.client';

export default {
    name: 'Products',
    components: { ProductCard },
    props: {
        component: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            products: [],
        };
    },
    async mounted() {
        await this.fetchProducts();
    },
    methods: {
        async fetchProducts() {
            try {
                const response = await this.$axios.get('/products', {
                    params: {
                        category_id: this.component.categories?.map((category) => category.id),
                        limit: this.component.products,
                        order_by: this.component.order_by,
                        order_dir: this.component.order_direction,
                    },
                });
                this.products = response.data;
            } catch (error) {
                // ignore
            }
        },
    },
};
</script>

<template>
    <div
        class="grid gap-8"
        :class="`grid-cols-${component.columns_mobile ?? 2} md:grid-cols-${component.columns ?? 1}`"
    >
        <ProductCard
            v-for="product in products"
            :key="product.id"
            :product="product"
        />
    </div>
</template>

<style scoped lang="scss">

</style>
