const middleware = {}

middleware['redirectToHomeIfBasketEmpty'] = require('../middleware/redirectToHomeIfBasketEmpty.js')
middleware['redirectToHomeIfBasketEmpty'] = middleware['redirectToHomeIfBasketEmpty'].default || middleware['redirectToHomeIfBasketEmpty']

middleware['referral'] = require('../middleware/referral.js')
middleware['referral'] = middleware['referral'].default || middleware['referral']

middleware['set-initiative-context'] = require('../middleware/set-initiative-context.js')
middleware['set-initiative-context'] = middleware['set-initiative-context'].default || middleware['set-initiative-context']

export default middleware
