<template>
    <div class="container mx-auto text-center text-grey-darker select-none leading-none">
        <component :is="component" />
    </div>
</template>

<script>
/**
 * The error view. Dynamically loads an error component based on the error status code.
 */
import Error404 from '@/components/Error404';
import Error500 from '@/components/Error500';

export default {

    components: {
        Error404,
        Error500,
    },

    props: {
        error: {
            type: Object,
            required: true,
        },
    },

    computed: {
        component() {
            if (this.error.statusCode === 404) {
                return Error404;
            }
            return Error500;
        },
    },

    layout: 'default',
};
</script>

<style lang="scss" scoped>
</style>
