import initialState from './initialState';

export default {

    reset(state) {
        Object.assign(state, initialState);
    },

    /**
     * Updates the basket.
     * We map over order items so that they can be stored according to our desired data structure.
     */
    updateBasket(state, order) {
        state.id = order.id;
        state.hash = order.hash;
        state.code = order.code;
        state.items = order.items.map((item) => ({
            product: {
                image: item.product.images[0],
                name: item.product.name,
                slug: item.product.slug,
                id: item.product.id,
            },

            id: item.id,
            price: item.unit_price,
            total: item.total_price,
            quantity: item.quantity,
            variations: item.variations,
            textVariation: item.text_variation || '',
            textVariationLine2: item.text_variation_line_2 || '',
            productVariationsCompound: item.product_variations_compound,
            customerImageUrl: item.customer_image_url,
            canvasPreview: item.canvas_preview || '',
            imageUrl: item.image_url,
            images: item.images || [],
            addOns: item.add_ons || [],
        }));
        state.totalPrice = order.grand_total;
        state.itemsPrice = order.item_total;
        state.shippingPrice = order.shipping_total;
        state.discountSavings = order.discount_real_value;
        state.discountCode = order.discount_code;
        state.orderedFor = order.ordered_for;
        state.cheapestShippingRate = order.cheapest_shipping_rate;
        state.clickPlus = order.is_click_plus;
        state.totalTax = order.tax_total;
    },

    updateOrder(state, payload) {
        state.order = payload;
    },

};
