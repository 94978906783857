import appboy from '@braze/web-sdk';

export default (ctx, inject) => {
    appboy.initialize('3dc0ee9a-2156-49ce-8d9f-9aef080950ad', {
        baseUrl: 'sdk.fra-01.braze.eu',
        enableLogging: false,
    });

    appboy.display.automaticallyShowNewInAppMessages();

    appboy.openSession();

    inject('braze', appboy);
};
