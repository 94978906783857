/**
 * Runs on initial page load, setting the user object in the database if the user is logged in.
 */

export default async ({ app: { store, $auth, $braze } }) => {
    if ($auth.loggedIn) {
        await store.dispatch('user/setUser', $auth.user);
        if (typeof $braze !== 'undefined') {
            $braze.changeUser(store.getters['user/id']);
        }
    }
};
