export default {
    SET_PRODUCT_VARIATIONS: (state, product) => {
        state.productVariations = product;
    },

    SET_TEXT: (state, text) => {
        state.text = text;
    },

    SET_TEXT_LINE_2: (state, textLine2) => {
        state.textLine2 = textLine2;
    },

    SET_FONT_ID: (state, fontId) => {
        state.fontId = fontId;
    },

    SET_FONT_FAMILY: (state, fontFamily) => {
        const selectedFont = state.fonts.find((font) => font.name === fontFamily);

        if (selectedFont) {
            state.fontId = selectedFont.id;
        } else {
            state.fontId = null;
        }
    },

    SET_FONTS: (state, fonts) => {
        state.fonts = fonts;
    },

    SET_CUSTOMER_IMAGE_URL: (state, customerImageUrl) => {
        state.customerImageUrl = customerImageUrl;
    },

    SET_CUSTOMER_IMAGE_WIDTH: (state, customerImageWidth) => {
        state.customerImageWidth = customerImageWidth;
    },

    SET_CUSTOMER_IMAGE_HEIGHT: (state, customerImageHeight) => {
        state.customerImageHeight = customerImageHeight;
    },

    SET_CUSTOMER_IMAGE_PREVIEW: (state, customerImagePreview) => {
        state.customerImagePreview = customerImagePreview;
    },

    SET_SELECTED_VARIATIONS: (state, selectedVariations) => {
        state.selectedVariations = selectedVariations;
    },

    ADD_SELECTED_VARIATION: (state, selectedVariation) => {
        state.selectedVariations.push(selectedVariation);
    },

    REMOVE_SELECTED_VARIATION: (state, selectedVariationId) => {
        const index = state.selectedVariations.findIndex((variation) => variation.variationType === selectedVariationId);

        if (index !== -1) {
            state.selectedVariations.splice(index, 1);
        }
    },

    SET_CANVAS_PREVIEW: (state, canvasPreview) => {
        state.canvasPreview = canvasPreview;
    },

    SET_SHOW_STOCK_NOTIFICATION: (state, showStockNotification) => {
        state.showStockNotification = showStockNotification;
    },

    SET_SHOW_PRODUCT_CUSTOMISATION_IMAGE: (state, showProductCustomisationImage) => {
        state.showProductCustomisationImage = showProductCustomisationImage;
    },

    SET_CUSTOMISATION_IMAGE: (state, customisationImage) => {
        state.customisationImage = customisationImage;
    },

    SET_DEFAULT_IMAGE: (state, defaultImage) => {
        state.defaultImage = defaultImage;
    },

    SET_IS_VARIATION_IMAGE: (state, isVariationImage) => {
        state.isVariationImage = isVariationImage;
    },

    SET_IS_BUY_BUTTON_LOADING: (state, isBuyButtonLoading) => {
        state.isBuyButtonLoading = isBuyButtonLoading;
    },

    SET_TOTAL_PRICE: (state, totalPrice) => {
        state.totalPrice = totalPrice;
    },

    SET_QUANTITY: (state, quantity) => {
        state.quantity = quantity;
    },

    SET_ACTIVE_VARIATION_IMAGE: (state, activeVariationImage) => {
        state.activeVariationImage = activeVariationImage;
    },

    RESET_STATE: (state) => {
        Object.assign(state, {
            productVariations: {},
            customisationImage: null,
            defaultImage: null,
            selectedVariations: [],
            text: '',
            textLine2: '',
            imageScale: 1,
            isBuyButtonLoading: false,
            totalPrice: 0,
            customerImageUrl: null,
            customerImagePreview: null,
            customerImageWidth: 100,
            customerImageHeight: 100,
            canvasPreview: null,
            showStockNotification: false,
            fontFamily: 'Mellony Dry Brush',
            showProductCustomisationImage: false,
            isVariationImage: false,
            activeVariationImage: null,
        });
    },
};
