export default () => ({

    navItems: [],
    pageCount: 0,
    showSidebar: false,
    blogPosts: [],
    homePage: {
        grids: [],
    },
    megaMenuOpen: false,
    directusSettings: {
        home_page: null,
        menu: null,
    },
    menu: {},

});
