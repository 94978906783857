import initialState from './initialState';

export default {

    reset(state) {
        Object.assign(state, initialState);
    },

    setUser(state, user) {
        state.user = { ...user };
        state.isLoggedIn = true;
    },

    setFirstName(state, firstName) {
        state.user.first_name = firstName;
    },

    setLastName(state, lastName) {
        state.user.last_name = lastName;
    },

    setEmail(state, email) {
        state.user.email = email;
    },

    setPhone(state, phone) {
        state.user.phone = phone;
    },

    setAddresses(state, addresses) {
        state.user.addresses = [
            ...addresses,
        ];
    },

};
